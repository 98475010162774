import * as React from 'react'
import classes from './FileUploadItem.module.less'
import clsx from 'clsx'
import { AuthImage } from '../AuthImage'
import { Card, Tooltip, Typography } from 'antd'
import { TPortalTypes, TUploadItem } from '@/types'
import { getMediaLink, isImageType, mapFileTypeToIcon } from '@/utils'

type TFileUploadItemProps = {
  currentFile?: TUploadItem
  actions?: React.ReactNode
  cardClassName?: string
  onClick?: () => void
  portal?: TPortalTypes
  showIcon?: boolean
  coverImgSrc?: string | null
}

export default function FileUploadItem({
  currentFile,
  actions,
  cardClassName,
  onClick,
  portal,
  showIcon = true,
  coverImgSrc,
}: TFileUploadItemProps) {
  if (currentFile == null) {
    return null
  }

  return (
    <div className={classes.ctn}>
      <Card
        hoverable
        className={clsx(classes.card, cardClassName)}
        onClick={onClick}
        cover={
          <AuthImage
            alt="Cover Image"
            src={
              coverImgSrc ||
              (isImageType(currentFile.mimeType)
                ? getMediaLink(currentFile.fileName, portal)
                : mapFileTypeToIcon(currentFile.mimeType))
            }
            className={classes['cover-image']}
          />
        }
      >
        <Card.Meta
          title={
            <>
              {showIcon && (
                <AuthImage
                  src={mapFileTypeToIcon(currentFile.mimeType)}
                  alt="iconType"
                />
              )}
              <Tooltip
                title={currentFile.name}
                placement="bottom"
                destroyTooltipOnHide
              >
                <Typography.Text className={classes['template-title']} ellipsis>
                  {currentFile.name}
                </Typography.Text>
              </Tooltip>
            </>
          }
          description={actions}
        />
      </Card>
    </div>
  )
}
