import axios from 'axios'

import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  useEffect,
  useState,
} from 'react'
import { Image, ImageProps } from 'antd'
import { keycloakClient } from '@/utils'

export const AuthImage = ({
  src,
  antdComponent = false,
  ...props
}: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> &
  ImageProps & {
    antdComponent?: boolean
  }) => {
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    let objectUrl: string | null = null
    if (src && keycloakClient.token) {
      axios({
        url: src,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${keycloakClient.token}`,
        },
      })
        .then(res => {
          if (res) {
            objectUrl = URL.createObjectURL(res.data)
            setImageUrl(objectUrl)
          }
        })
        .catch(err => err)
    }

    return () => {
      objectUrl && URL.revokeObjectURL(objectUrl)
    }
  }, [src, keycloakClient.token])

  if (antdComponent) return <Image src={imageUrl} alt="image" {...props} />

  return <img src={imageUrl} alt={props.alt} {...props} />
}
