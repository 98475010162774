import useAuth from './useAuth'
import { MainLayoutRoutes, flattenRoutes } from '@/App.route'
import { TRoute } from '@/types/common'
import { matchPath, useLocation } from 'react-router'
import { useMemo } from 'react'

export default function useRoutes() {
  const location = useLocation()
  const { hasPermission, userPermission } = useAuth()

  const route = useMemo(
    () => flattenRoutes.find(val => matchPath(val.path, location.pathname)),
    [location]
  )

  const authorizedRoutes = useMemo(
    () =>
      MainLayoutRoutes.children?.reduce<TRoute[]>(function reduceFn(
        prev,
        curr
      ) {
        if (hasPermission(curr.page, curr.permission)) {
          if (curr.children) {
            return [
              ...prev,
              {
                ...curr,
                children: curr.children.reduce<TRoute[]>(reduceFn, []),
              },
            ]
          }
          return [...prev, curr]
        }
        return prev
      },
      []) || [],
    [userPermission]
  )

  return { route, authorizedRoutes }
}
