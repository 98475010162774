import classes from './DoneIcon.module.less'
import { RiCheckFill } from 'react-icons/ri'

const DoneICon = () => {
  return (
    <div className={classes['icon-container']}>
      <RiCheckFill />
    </div>
  )
}

export default DoneICon
