import SignIn from '@/pages/SignIn'
import { RootPaths } from '@/constant/path'

import type { TRoute } from '@/types/common/route'

export const SignInRoutes: TRoute = {
  path: RootPaths.SIGN_IN,
  component: SignIn,
  page: 'accessible',
}
