import * as React from 'react'
import { Navigate, matchRoutes, useLocation } from 'react-router-dom'
import { OverlayLoader } from '@sas/components-fe'
import {
  TCountryName,
  TKeyPermission,
  TPortalPages,
  TUserPermissionResponse,
  TUserProfileResponse,
} from '@/types/models'
import { createWithEqualityFn } from 'zustand/traditional'
import { setRedirectUrl } from '@/utils'
import { useEffect } from 'react'
import { useGetProfile, usePermission } from '@/api/hooks/user.query'
import { useKeycloak } from '@react-keycloak/web'
import { useLocalStorage } from 'usehooks-ts'
import { useTranslation } from 'react-i18next'

export type TAuthStore = {
  authData: {
    userPermission: TUserPermissionResponse | null
    userProfile: TUserProfileResponse | null
    country: TCountryName
  }
  setUserPermission: (userPermission: TUserPermissionResponse | null) => void
  setUserProfile: (userProfile: TUserProfileResponse | null) => void
  hasPermission: (page?: TPortalPages, permission?: TKeyPermission) => boolean
  setCountry: (country: TCountryName) => void
}

export const useAuthStore = createWithEqualityFn<TAuthStore>((set, get) => ({
  authData: {
    userPermission: null,
    userProfile: null,
    country: 'vietnam',
  },
  hasPermission: (page?: TPortalPages, permission: TKeyPermission = 'view') => {
    const userPermission = get().authData.userPermission
    const country = get().authData.country
    if (page === 'accessible') return true
    if (!userPermission) return false
    if (page == null || permission == null) return false
    return userPermission.permission?.[country]?.[page]?.[permission] || false
  },
  setUserPermission: (userPermission: TUserPermissionResponse | null) =>
    set(state => ({ authData: { ...state.authData, userPermission } })),
  setUserProfile: (userProfile: TUserProfileResponse | null) =>
    set(state => ({ authData: { ...state.authData, userProfile } })),
  setCountry: (country: TCountryName) =>
    set(state => ({ authData: { ...state.authData, country } })),
}))

const useAuth = () => {
  const [currentCountry, setCurrentCountry] = useLocalStorage<TCountryName>(
    'country',
    'vietnam'
  )
  const auth = useAuthStore(state => ({
    ...state.authData,
    setUserPermission: state.setUserPermission,
    hasPermission: state.hasPermission,
    setUserProfile: state.setUserProfile,
    setCountry: state.setCountry,
  }))

  useEffect(() => {
    auth.setCountry(currentCountry)
  }, [currentCountry])

  return { ...auth, currentCountry, setCurrentCountry }
}

export const AuthLoader = ({ children }: React.PropsWithChildren) => {
  const { setUserProfile, setUserPermission } = useAuth()
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const isRouteSignIn =
    (matchRoutes([{ path: '/sign-in' }], location)?.length || 0) > 0 || false
  const { keycloak, initialized } = useKeycloak()
  const queryPermission = usePermission({
    queryConfig: {
      enabled: !!keycloak.authenticated && !!keycloak.token,
    },
  })

  const queryProfile = useGetProfile({
    queryConfig: {
      enabled: !!keycloak.authenticated && !!keycloak.token,
    },
  })

  useEffect(() => {
    if (!queryPermission.isLoading)
      setUserPermission(queryPermission.data || null)
  }, [queryPermission.isLoading, queryPermission.data])

  useEffect(() => {
    if (!queryProfile.isLoading) setUserProfile(queryProfile.data || null)
  }, [queryProfile.isLoading, queryProfile.data])

  // keycloak loading
  if (!initialized) {
    return <OverlayLoader text={t('COMMON/AUTHENTICATING')} />
  }

  // detect not authorize
  if (keycloak.authenticated === false && !isRouteSignIn) {
    const redirectUrl = setRedirectUrl()
    return <Navigate to={`/sign-in${redirectUrl ? `?${redirectUrl}` : ''}`} />
  }

  if (queryPermission.isLoading || queryProfile.isLoading) {
    return <OverlayLoader text={t('COMMON/LOADING_PERMISSIONS')} />
  }

  if (!i18n.isInitialized) {
    return <OverlayLoader text={t('COMMON/LOADING_LANGUAGES')} />
  }

  return <>{children}</>
}

export default useAuth
