import qs from 'query-string'
import { HttpService } from './http-service'
import { REST_API_CLAIMS } from '@/api'
import {
  TBreakDownResponse,
  TClaimAttchmentReponse,
  TClaimBasicInfo,
  TClaimDetailResponse,
  TClaimHistoryParams,
  TClaimHistoryPayload,
  TClaimHistoryResponse,
  TClaimPaymentBreakdownResponse,
  TClaimsParams,
  TClaimsResponse,
  TGeneralInformationResponse,
  TMemberPolicyLimitResponse,
  TPaginationResponse,
} from '@/types'
import { TClaimPaymentNoticeResponse } from './../../types/api/claims.d'
import { buildRestUrl } from '@/config'

export const getClaimDetail = async (
  id: number
): Promise<TClaimDetailResponse | null> => {
  const route = buildRestUrl(REST_API_CLAIMS.GET_CLAIM_DETAIL.uri, {
    claimNo: id,
  })

  return HttpService.get<TClaimDetailResponse>(route)
}

export const getGeneralInformation = async (
  id: string
): Promise<TGeneralInformationResponse | null> => {
  const route = buildRestUrl(REST_API_CLAIMS.GET_GENERAL_INFORMATION.uri, {
    id,
  })

  return HttpService.get<TGeneralInformationResponse>(route)
}

export const getBreakdown = async (
  id: string
): Promise<Array<TBreakDownResponse> | null> => {
  const route = buildRestUrl(REST_API_CLAIMS.GET_BREAKDOWN.uri, {
    id,
  })

  return HttpService.get<Array<TBreakDownResponse>>(route)
}

export const getClaimHistory = async (
  payload: TClaimHistoryPayload,
  params: TClaimHistoryParams
): Promise<TPaginationResponse<TClaimHistoryResponse[]>> => {
  const route = `${buildRestUrl(REST_API_CLAIMS.GET_CLAIM_HISTORY.uri, {
    memberNumber: payload.memberNumber,
    suffix: payload.suffix,
  })}?${qs.stringify(params)}`

  return HttpService.get<TPaginationResponse<TClaimHistoryResponse[]>>(route)
}

export const getMemberPolicyLimit = async (
  memberCard: string,
  memberCardPad: string
): Promise<TMemberPolicyLimitResponse | null> => {
  const route = buildRestUrl(REST_API_CLAIMS.GET_MEMBER_POLICY_LIMIT.uri, {
    memberCard,
    memberCardPad,
  })

  return HttpService.get<TMemberPolicyLimitResponse>(route)
}

export const getClaimAttachmentMobile = async (
  claimNo: number
): Promise<TClaimAttchmentReponse[]> => {
  const route = buildRestUrl(REST_API_CLAIMS.GET_ATTACHMENT_MOBILE.uri, {
    claimNo,
  })

  return HttpService.get<TClaimAttchmentReponse[]>(route)
}

export const getClaimAttachmentExternal = async (
  claimNo: number
): Promise<TClaimAttchmentReponse[]> => {
  const route = buildRestUrl(REST_API_CLAIMS.GET_ATTACHMENT_EXTERNAL.uri, {
    claimNo,
  })

  return HttpService.get<TClaimAttchmentReponse[]>(route)
}

export const getClaims = async (
  payload: TClaimsParams
): Promise<TPaginationResponse<TClaimsResponse[]>> => {
  const route = `${REST_API_CLAIMS.GET_CLAIMS.uri}?${qs.stringify(payload)}`

  return HttpService.get<TPaginationResponse<TClaimsResponse[]>>(route)
}

export const getClaimById = async (
  claimNo: number
): Promise<TClaimBasicInfo> => {
  const route = buildRestUrl(REST_API_CLAIMS.GET_CLAIM_BY_ID.uri, {
    claimNo,
  })

  return HttpService.get<TClaimBasicInfo>(route)
}

export const getDataForExportPDF = async (
  claimNo: number
): Promise<[TClaimPaymentNoticeResponse, TClaimPaymentBreakdownResponse]> => {
  const routePaymentNotice = buildRestUrl(
    REST_API_CLAIMS.GET_PAYMENT_NOTICE.uri,
    {
      id: claimNo,
    }
  )
  const routePaymentBreakdown = buildRestUrl(
    REST_API_CLAIMS.GET_BREAKDOWN_V2.uri,
    {
      id: claimNo,
    }
  )

  return Promise.all([
    HttpService.get<TClaimPaymentNoticeResponse>(routePaymentNotice),
    HttpService.get<TClaimPaymentBreakdownResponse>(routePaymentBreakdown),
  ])
}
