import * as React from 'react'
import useAuth from './useAuth'
import { ExportEvent } from '@/constant'
import { Socket, io } from 'socket.io-client'
import { TExportEvent } from '@/types/api'
import { appConfig } from '@/config/appConfig'
import { createContext, useContext, useEffect, useState } from 'react'
import { keycloakClient, safeParseJsonType } from '@/utils'

type TWebsocketContextProp = {
  exportEvent: TExportEvent | null
  refetchData: () => void
}

type TWebsocketProviderProps = {
  children?: React.ReactNode
}

const WebsocketContext = createContext<TWebsocketContextProp>({
  exportEvent: null,
  refetchData: () => {},
})

export const WebsocketProvider = ({ children }: TWebsocketProviderProps) => {
  const [state, setState] = useState<TExportEvent | null>(null)
  const { userPermission } = useAuth()

  const refetchData = () => {
    setState({
      eventName: ExportEvent.REFETCH_DATA,
    })
  }

  useEffect(() => {
    let socket: Socket

    if (userPermission?.id && appConfig.WS_ENDPOINT) {
      socket = io(appConfig.WS_ENDPOINT, {
        transports: ['websocket'],
        secure: true,
        auth: {
          token: keycloakClient.token,
          realmName: appConfig.REALM_NAME,
        },
      })

      socket.on('notifications', msg => {
        const event = safeParseJsonType<TExportEvent>(msg)
        if (event.portalName === appConfig.PORTAL_NAME) {
          setState(event)
          setTimeout(() => {
            setState(null)
          }, 4000)
        }
      })
    }

    // @ts-ignore
    if (!userPermission?.id && socket !== undefined) {
      socket.disconnect()
    }

    return () => {
      socket && socket.disconnect()
    }
  }, [userPermission?.id])

  return (
    <WebsocketContext.Provider
      value={{
        exportEvent: state,
        refetchData: refetchData,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  )
}

export const useWebsocket = () => {
  return useContext(WebsocketContext)
}
