import { AxiosProgressEvent } from 'axios'
import { HttpService } from './http-service'
import { REST_API_UPLOAD } from '../urls'
import { TUploadItem, TUploadResponse } from '@/types'
import { buildRestUrl } from '@/config'

export const getUploadDocument = async (
  payload: TUploadResponse
): Promise<TUploadItem> => {
  const url = buildRestUrl(REST_API_UPLOAD.GET_DOCUMENT.uri, {
    id: payload.id,
  })
  return await HttpService.get<TUploadItem>(url)
}

export const createUploadDocument = async (
  formData: FormData,
  onUploadProgress: (event: AxiosProgressEvent) => void,
  onUploadSuccess: (response: TUploadItem) => void
): Promise<void> => {
  return await HttpService.post<FormData, TUploadResponse>(
    REST_API_UPLOAD.UPLOAD_DOCUMENT.uri,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onDownloadProgress: event => onUploadProgress(event),
    }
  )
    .then(getUploadDocument)
    .then(onUploadSuccess)
}
