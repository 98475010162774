import * as React from 'react'
import { TKeyPermission, TPortalPages } from '@/types/models'
import { useAuth, useRoutes } from '@/hooks'

export type TAuthWrapperProps = {
  page?: TPortalPages
  permission?: TKeyPermission
  fallback?: React.ReactNode
  condition?: (page?: TPortalPages, permission?: TKeyPermission) => boolean
}

export default function AuthWrapper({
  page: currentPage,
  permission: currentPermission,
  children,
  fallback,
  condition,
}: React.PropsWithChildren<TAuthWrapperProps>) {
  const { hasPermission } = useAuth()
  const { route } = useRoutes()

  const page = currentPage || route?.page
  const permission = currentPermission || route?.permission

  if (
    !hasPermission(page, permission) ||
    (condition != null && !condition(page, permission))
  ) {
    return <>{fallback}</>
  }

  return <>{children}</>
}
