import {
  HttpService,
  getClaimHistory,
  getGeneralInformation,
} from '@/api/http-service'
import { REST_API_CLAIMS } from '../urls'
import { TApiError } from '../errors'
import {
  TBreakDownResponse,
  TClaimAttchmentReponse,
  TClaimBasicInfo,
  TClaimDetailResponse,
  TClaimHistoryParams,
  TClaimHistoryPayload,
  TClaimHistoryResponse,
  TClaimPaymentBreakdownResponse,
  TClaimPaymentNoticeResponse,
  TClaimsParams,
  TClaimsResponse,
  TCreateExportPayload,
  TCreateExportResponse,
  TGeneralInformationResponse,
  TMemberPolicyLimitResponse,
  TPaginationResponse,
  TUpdateAttachmentPayload,
} from '@/types'
import { isEmpty, omit } from 'lodash'

import { EReportTypeExport } from '@/constant'
import {
  getBreakdown,
  getClaimAttachmentExternal,
  getClaimAttachmentMobile,
  getClaimById,
  getClaimDetail,
  getDataForExportPDF,
  getMemberPolicyLimit,
} from '@/api'
import { getClaims } from '../http-service'
import { useMutation, useQuery } from 'react-query'

export function useGetClaimDetail(id: string) {
  return useQuery<TClaimDetailResponse | null, TApiError>(
    [REST_API_CLAIMS.GET_CLAIM_DETAIL, id],
    () => getClaimDetail(Number(id)),
    { enabled: !!id }
  )
}

export function useGetGeneralInformation(id: string) {
  return useQuery<TGeneralInformationResponse | null, TApiError>(
    [REST_API_CLAIMS.GET_GENERAL_INFORMATION, id],
    () => getGeneralInformation(id),
    { enabled: !!id }
  )
}

export function useGetBreakdown(id: string) {
  return useQuery<Array<TBreakDownResponse> | null, TApiError>(
    [REST_API_CLAIMS.GET_BREAKDOWN, id],
    () => getBreakdown(id),
    { enabled: !!id }
  )
}

export function useGetClaimHistory(
  payload: TClaimHistoryPayload,
  params: TClaimHistoryParams,
  enabled: boolean = false
) {
  return useQuery<TPaginationResponse<TClaimHistoryResponse[]>, TApiError>(
    [REST_API_CLAIMS.GET_CLAIM_HISTORY, params, payload],
    () => getClaimHistory(payload, params),
    { enabled: !!payload.memberNumber && enabled }
  )
}

export function useGetMemberPolicyLimit(
  memberCard: string,
  memberCardPad: string
) {
  return useQuery<TMemberPolicyLimitResponse | null, TApiError>(
    [REST_API_CLAIMS.GET_MEMBER_POLICY_LIMIT, memberCard],
    () => getMemberPolicyLimit(memberCard, memberCardPad),
    { enabled: !!(memberCard && memberCardPad) }
  )
}

export const useAttachmentMobile = (claimNo: number) => {
  return useQuery<TClaimAttchmentReponse[], TApiError>(
    [REST_API_CLAIMS.GET_ATTACHMENT_MOBILE],
    () => getClaimAttachmentMobile(claimNo),
    { enabled: claimNo > 0 }
  )
}

export const useAttachmentExternal = (claimNo: number) => {
  return useQuery<TClaimAttchmentReponse[], TApiError>(
    [REST_API_CLAIMS.GET_ATTACHMENT_EXTERNAL, claimNo],
    () => getClaimAttachmentExternal(claimNo),
    { enabled: claimNo > 0, cacheTime: 0 }
  )
}

export const useUpdateAttachment = () => {
  return useMutation<void, TApiError, TUpdateAttachmentPayload>(
    async (payload: TUpdateAttachmentPayload) =>
      await HttpService.post<TUpdateAttachmentPayload, void>(
        REST_API_CLAIMS.UPDATE_ATTACHMENT.uri,
        payload
      )
  )
}

export function useGetClaims(payload: TClaimsParams) {
  return useQuery<TPaginationResponse<TClaimsResponse[]>, TApiError>(
    [REST_API_CLAIMS.GET_CLAIMS.uri, payload],
    () => getClaims(payload)
  )
}

export function useGetSearchClaims(payload: TClaimsParams, isEnable = false) {
  return useQuery<TPaginationResponse<TClaimsResponse[]>, TApiError>(
    [REST_API_CLAIMS.GET_CLAIMS.uri, 'search_page', payload],
    () => {
      const params = omit(payload, ['page', 'limit'])
      return isEmpty(params) ? { total: 0, data: [] } : getClaims(payload)
    },
    {
      enabled: isEnable,
    }
  )
}

export const useGetClaimsMutation = () => {
  return useMutation<
    TPaginationResponse<TClaimsResponse[]>,
    TApiError,
    TClaimsParams
  >([REST_API_CLAIMS.GET_CLAIMS], payload => getClaims(payload))
}

export const useGetClaimById = () => {
  return useMutation<TClaimBasicInfo, TApiError, { claimNo: number }>(
    ({ claimNo }) => getClaimById(claimNo)
  )
}

export function useCreateExport() {
  return useMutation<TCreateExportResponse, TApiError, TCreateExportPayload>(
    async (payload: TCreateExportPayload) => {
      const uri =
        payload.type === EReportTypeExport.REPORT_DETAIL
          ? REST_API_CLAIMS.CREATE_EXPORT_PENDING_CLAIMS.uri
          : REST_API_CLAIMS.CREATE_EXPORT_CLAIM_GENERAL.uri
      return await HttpService.post<
        TCreateExportPayload,
        TCreateExportResponse
      >(uri, payload)
    }
  )
}

export const useGetDataForExportPDF = (claimNo: number | string) => {
  return useQuery<
    [TClaimPaymentNoticeResponse, TClaimPaymentBreakdownResponse],
    TApiError
  >(
    [REST_API_CLAIMS.GET_PAYMENT_NOTICE.uri, +claimNo],
    () => getDataForExportPDF(+claimNo),
    { enabled: false }
  )
}
