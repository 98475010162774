import SignOut from '@/pages/SignOut'
import { RootPaths } from '@/constant/path'

import type { TRoute } from '@/types/common/route'

export const SignOutRoutes: TRoute = {
  path: RootPaths.SIGN_OUT,
  component: SignOut,
  page: 'accessible',
}
