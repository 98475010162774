export enum TemplateType {
  Email = 'email',
  SMS = 'sms',
}

export enum CommonStatus {
  WAITING_FOR_REVIEW = 'Waiting for review',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  ON_HOLD = 'On Hold',
  READY = 'Ready',
  MISSING_INFORMATION = 'Missing Information',
  SENT = 'Sent',
}

export enum ClaimHistoryStatus {
  READY = 'Ready',
  ON_HOLD = 'On Hold',
  MISSING_INFORMATION = 'Missing Information',
  SENT = 'Sent',
  PENDING = 'Pending',
}

export enum EPolicyStatus {
  IN_FORCE = 'In Force',
  PROPOSAL = 'Proposal',
  UNDER_WRITING = 'Underwriting',
  LASPED = 'Lasped',
  TERMINATED = 'Terminated',
}

export enum EClaimStatus {
  ARCHIVED = 'ARCHIVED', // 0
  PENDING_REVIEW = 'PENDING_REVIEW', // 1
  PENDING_INSURER = 'PENDING_INSURER', // 2
  PENDING_DOCUMENT = 'PENDING_DOCUMENT', // 4
  PENDING_SETTLEMENT = 'PENDING_SETTLEMENT', // 5 //
  PENDING_REVISION = 'PENDING_REVISION', // 9
  PENDING_HOD = 'PENDING_HOD', // 10
  PARTIAL_APPROVED = 'PARTIAL_APPROVED', // 7 or 8
  APPROVED = 'APPROVED', // 7 or 8
  REJECTED = 'REJECTED', // 7 or 8
  CANCEL = 'CANCEL',
  RETURN = 'RETURN',
  SUBMITTED = 'SUBMITTED',
  WAITING_FOR_TRANSFER = 'WAITING_FOR_TRANSFER',
  TRANSFERRED = 'TRANSFERRED',
  TRANSFER_TO_INSURER = 'TRANSFER_TO_INSURER',
  SETTLED = 'SETTLED', // 7 or 8
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PENDING_PREMIUM = 'PENDING_PREMIUM',
}

export enum ETotalClaimStatus {
  Approved = 0,
  Pending = 1,
  Rejected = 2,
}

export enum EClaimType {
  DIRECT_BILLING = 'DIRECT_BILLING',
  REIMBURSEMENT = 'REIMBURSEMENT',
}

export enum Lang {
  EN = 'en',
  VI = 'vi',
}

export enum EExportStatus {
  REQUEST = 'EXPORT_REQUEST',
  SUCCESS = 'EXPORT_SUCCESS',
  ERROR = 'EXPORT_ERROR',
  CANCEL = 'EXPORT_CANCEL',
}

export enum ExportEvent {
  REFETCH_DATA = 'REFETCH_DATA',
  CLAIM_PARTNER_EXPORT_SUCCESS = 'CLAIM_PARTNER_EXPORT_SUCCESS',
  CLAIM_PARTNER_EXPORT_DETAIL_SUCCESS = 'CLAIM_PARTNER_EXPORT_DETAIL_SUCCESS',
  EXPORT_PARTNER_CLAIM_LIST_GENERAL_SUCCESS = 'EXPORT_PARTNER_CLAIM_LIST_GENERAL_SUCCESS',
}

export enum AmountCardName {
  TOTAL_PAID_AMOUNT = 'Total Paid Amount',
  AVG_PAID_AMOUNT = 'Avg Paid Amount',
  TOTAL_REQUEST_AMOUNT = 'Total Request Amount',
}

export enum EBeneficiaryType {
  ALL = 'ALL',
  REIMBURSEMENT = 'REIMBURSEMENT',
  DIRECT_BILLING = 'DIRECT_BILLING',
}

export enum EPeriodTime {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
}

export enum EServiceType {
  DENTAL = 'DENTAL',
  INPATIENT = 'INPATIENT',
  MATERNITY = 'MATERNITY',
  OUTPATIENT = 'OUTPATIENT',
  OUTPATIENT_TREATMENT = 'OUTPATIENT_TREATMENT',
  MEDICAL_EXPENSES_ACCIDENT = 'MEDICAL_EXPENSES_ACCIDENT',
  OTHER_BENEFIT = 'OTHER_BENEFIT',
  DEATH_DISABLEMENT = 'DEATH_DISABLEMENT',
}

export enum ERelationship {
  EMPLOYEE = 'EMPLOYEE',
  ASCENDANT = 'ASCENDANT',
  CHILD = 'CHILD',
  SPOUSE = 'SPOUSE',
  MEMBER = 'MEMBER',
  OTHER = 'OTHER',
  HUSBAND = 'HUSBAND',
  WIFE = 'WIFE',
  ADOPTED_CHILD = 'ADOPTED_CHILD',
  PARENT = 'PARENT',
  STEP_PARENT = 'STEP_PARENT',
  PARENT_IN_LAW = 'PARENT_IN_LAW',
  GRANDPARENT = 'GRANDPARENT',
  GRANDCHILD = 'GRANDCHILD',
  GUARDIAN = 'GUARDIAN',
  SIBLING = 'SIBLING',
  SIBLING_IN_LAW = 'SIBLING_IN_LAW',
  AUNT_UNCLE = 'AUNT_UNCLE',
  NIECE_NEPHEW = 'NIECE_NEPHEW',
}

export enum EFilterPageLevel {
  PLAN = 'groupId',
  POLICY = 'policyNo',
}

export enum ETypeExport {
  ZIP = 'zip',
  XLSX = 'xlsx',
}

export enum EReportTypeExport {
  REPORT_DETAIL = 'REPORT_DETAIL',
  GENERAL_REPORT = 'GENERAL_DATA',
}

export enum ERelationshipType {
  MEMBER = 'MEMBER',
  CHILD = 'CHILD',
  SPOUSE = 'SPOUSE',
  OTHER = 'OTHER',
  HUSBAND = 'HUSBAND',
  WIFE = 'WIFE',
  ADOPTED_CHILD = 'ADOPTED_CHILD',
  PARENT = 'PARENT',
  STEP_PARENT = 'STEP_PARENT',
  PARENT_IN_LAW = 'PARENT_IN_LAW',
  GRANDPARENT = 'GRANDPARENT',
  GRANDCHILD = 'GRANDCHILD',
  GUARDIAN = 'GUARDIAN',
  SIBLING = 'SIBLING',
  SIBLING_IN_LAW = 'SIBLING_IN_LAW',
  AUNT_UNCLE = 'AUNT_UNCLE',
  NIECE_NEPHEW = 'NIECE_NEPHEW',
}
