import classes from './GeneralInformation.module.less'
import isArray from 'lodash/isArray'
import uniqueId from 'lodash/uniqueId'
import { Collapse, CollapseProps, Spin } from 'antd'
import { RootPaths } from '@/constant'
import { TGeneralInformationConfig } from './GeneralInformation.config'
import { Typography, openNotification } from '@sas/components-fe'
import { isEmpty } from 'lodash'
import { useGetGeneralInformation } from '@/api'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const GeneralInformation = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { claimId } = useParams()
  const {
    data: generalInformation,
    isLoading: claimLoading,
    isSuccess,
  } = useGetGeneralInformation(claimId || '')

  if (isSuccess && !generalInformation) {
    openNotification({
      notificationType: 'Error',
      message: t('CLAIMS/CLAIM_ID_NOT_EXISTS', { claimId }),
    })
    navigate(RootPaths.CLAIMS)
    return null
  }

  if (claimLoading) {
    return <Spin />
  }

  if (!generalInformation) {
    return null
  }

  const generalInformationConfig: TGeneralInformationConfig[] = [
    {
      title: t('CLAIMS/GENERAL_INFO'),
      children: [
        {
          title: t('CLAIMS/CLAIM_ID'),
          content: generalInformation?.claimNo,
        },
        {
          title: t('CLAIMS/RECEIPT_DATE'),
          content: generalInformation?.receivedDate,
        },
        {
          title: t('CLAIMS/APPROVAL_DATE'),
          content: generalInformation?.approvalDate,
        },
        {
          title: t('CLAIMS/PAYMENT_NUMBER'),
          content: generalInformation?.paymentNumber,
        },
        {
          title: t('CLAIMS/PROVIDER_NAME'),
          content: generalInformation?.providers,
          style: {
            gridColumn: '1/3',
          },
        },
        {
          title: t('CLAIMS/DIAGNOSIS'),
          content: generalInformation?.diagnosis || [],
          style: {
            gridColumn: '3/5',
          },
        },
        {
          title: t('CLAIMS/REMARK'),
          content: generalInformation?.remark,
          style: {
            gridColumn: '1/5',
          },
        },
      ],
    },
  ]

  const renderContent = (content?: string | string[] | number) => {
    if (!content || isEmpty(content)) {
      return '-'
    }

    if (isArray(content)) {
      return (
        <ul className={classes['diagnosis-wrapper']}>
          {content.map(el => (
            <li key={uniqueId()}>
              <Typography
                colorType="text"
                colorVariant={100}
                fontWeight={400}
                fontSize="0.875rem"
              >
                {el}
              </Typography>
            </li>
          ))}
        </ul>
      )
    }

    return content
  }

  return (
    <div className={classes.ctn}>
      {generalInformationConfig.map(({ title, children }) => {
        const collapseItems: CollapseProps['items'] = [
          {
            key: title,
            label: title,
            children: (
              <div className={classes.grid}>
                {children?.map(({ title: childTitle, content, style }) => (
                  <div
                    key={childTitle}
                    className={classes['grid-item']}
                    style={style}
                  >
                    <div className={classes['grid-item-title']}>
                      {childTitle}
                    </div>
                    <div className={classes['grid-item-content']}>
                      {renderContent(content || '-')}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
        ]

        return (
          <Collapse
            key={title}
            items={collapseItems}
            defaultActiveKey={[`${title}`]}
            className={classes.collapse}
          />
        )
      })}
    </div>
  )
}

export default GeneralInformation
