import classes from './Attachment.module.less'
import { Col, Empty, Row, Skeleton } from 'antd'
import { FilePreview, FileUploadItem } from '@/components'
import { RiDownloadLine } from 'react-icons/ri'
import { TClaimAttchmentReponse } from '@/types/api'
import { TPortalTypes } from '@/types/common'
import { Typography, downloadAuthMedia } from '@sas/components-fe'
import { getMediaLink, getRangeNumber, keycloakClient } from '@/utils'
import { useAttachmentExternal, useAttachmentMobile } from '@/api'
import { useParams } from 'react-router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type TRenderAttachmentProps = {
  handleOpenPreview: (
    attachment: TClaimAttchmentReponse,
    portal?: TPortalTypes
  ) => void
  attachments: TClaimAttchmentReponse[]
  portal?: TPortalTypes
  loading?: boolean
}

const RenderAttachment = ({
  handleOpenPreview,
  attachments,
  portal,
  loading,
}: TRenderAttachmentProps) => {
  return (
    <Row gutter={[24, 24]}>
      {attachments.length > 0 ? (
        attachments.map(attachment => {
          return (
            <Col span={24} md={6} xl={4} key={attachment.id}>
              <FileUploadItem
                currentFile={attachment}
                onClick={() => handleOpenPreview(attachment, portal)}
                portal={portal}
                actions={
                  <RiDownloadLine
                    onClick={e => {
                      e.stopPropagation()
                      downloadAuthMedia(
                        getMediaLink(attachment.fileName, portal),
                        attachment.name,
                        keycloakClient.token!
                      )
                    }}
                  />
                }
              />
            </Col>
          )
        })
      ) : (
        <Col span={24}>
          <Empty />
        </Col>
      )}
      {loading &&
        getRangeNumber(6).map(num => (
          <Col key={num} md={4} xl={4} style={{ textAlign: 'center' }}>
            <Skeleton.Image active />
          </Col>
        ))}
    </Row>
  )
}

const Attachment = () => {
  const { claimId: claimNo } = useParams()
  const claimId = Number(claimNo || '')
  const [isOpenPreview, setIsOpenPreview] = useState(false)
  const [currentFile, setCurrentFile] = useState<
    TClaimAttchmentReponse | undefined
  >()
  const [selectPortalType, setSelectPortalType] =
    useState<TPortalTypes>('CLAIMS_PORTAL')
  const queryAttachmentMobile = useAttachmentMobile(claimId)
  const queryAttachmentExternal = useAttachmentExternal(claimId)
  const { t } = useTranslation()

  const handleOpenPreview =
    (portal: TPortalTypes) => (attachment: TClaimAttchmentReponse) => {
      setIsOpenPreview(true)
      setSelectPortalType(portal)
      setCurrentFile(attachment)
    }

  const handleClosePreview = () => {
    setIsOpenPreview(false)
    setCurrentFile(undefined)
  }

  return (
    <>
      <div className={classes.ctn}>
        <div className={classes['mt-24']}>
          <Typography
            colorType="text"
            colorVariant={100}
            fontWeight={600}
            fontSize="1rem"
          >
            <span>{t('CLAIMS/MEMBER_UPLOAD_ATTACHMENTS')}</span>
          </Typography>
          {queryAttachmentMobile.data && (
            <RenderAttachment
              handleOpenPreview={handleOpenPreview('MEMBER_PORTAL')}
              attachments={queryAttachmentMobile.data}
              portal="MEMBER_PORTAL"
              loading={queryAttachmentMobile.isLoading}
            />
          )}
        </div>
        <div className={classes['mt-24']}>
          <Typography
            colorType="text"
            colorVariant={100}
            fontWeight={600}
            fontSize="1rem"
          >
            {t('CLAIMS/EXTERNAL_FACING_ATTACHMENTS')}
          </Typography>
          {queryAttachmentExternal.data && (
            <RenderAttachment
              handleOpenPreview={handleOpenPreview('CLAIMS_PORTAL')}
              attachments={queryAttachmentExternal.data}
              portal="CLAIMS_PORTAL"
              loading={queryAttachmentExternal.isLoading}
            />
          )}
        </div>
      </div>
      {currentFile && (
        <FilePreview
          currentFile={currentFile}
          open={isOpenPreview}
          selectPortalType={selectPortalType}
          onClose={handleClosePreview}
        />
      )}
    </>
  )
}

export default Attachment
