import { useEffect, useState } from 'react'

export default function useDocumentTitle(
  initialValue?: string,
  suffix = 'FHVI Partners Management'
): [
  string | undefined,
  React.Dispatch<React.SetStateAction<string | undefined>>
] {
  const [title, setTitle] = useState<string | undefined>(
    initialValue || document.title
  )

  useEffect(() => {
    document.title = suffix
      ? title
        ? `${title} - ${suffix}`
        : suffix
      : title || ''
  }, [title])

  return [title, setTitle]
}
