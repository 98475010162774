export const REST_API_EXPORT = {
  GET_EXPORT_HISTORY: {
    uri: 'export/v1/export-history',
    method: 'GET',
  },
  CREATE_EXPORT_CLAIM_DETAIL: {
    uri: 'partner-portal/v1/export/claims-detail',
    method: 'GET',
  },
  CANCEL_EXPORT_LIST_CLAIM: {
    uri: 'partner-portal/v1/export/claims/cancel/:id',
    method: 'PATCH',
  },
  CHECK_EXPORT_REQUEST: {
    uri: 'export/v1/check-export-request',
    method: 'GET',
  },
}
