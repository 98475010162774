import * as React from 'react'
import classes from './FileUploader.module.less'
import clsx from 'clsx'
import update from 'immutability-helper'
import { FileItem } from './components/FileItem'
import { RcFile, UploadChangeParam } from 'antd/lib/upload/interface'
import { RiUploadCloudFill } from 'react-icons/ri'
import { TFileUploadItem, TUploadItem } from '@/types'
import { Upload, UploadProps } from 'antd'
import { findIndex } from 'lodash'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { usePrevious } from '@/hooks'
import { useTranslation } from 'react-i18next'

const { Dragger } = Upload

export type TFileUploaderProps = {
  onUploading?: () => void
  onError?: () => void
  onUploadCompleted: (uploadItems: TUploadItem[]) => void
  className?: string
  draggerClassName?: string
  children?: React.ReactNode
  maxFileUpload?: number
  maxFileTotal?: number
}

export type TFileUploaderRef = {
  uploadItems: TFileUploadItem[]
  setUploadItems: React.Dispatch<React.SetStateAction<TFileUploadItem[]>>
}

const FileUploader = forwardRef<TFileUploaderRef, TFileUploaderProps>(
  (
    {
      onUploadCompleted = () => {},
      onUploading = () => {},
      onError = () => {},
      className,
      draggerClassName,
      children,
      maxFileUpload = 1,
      maxFileTotal,
    },
    ref
  ) => {
    const { t } = useTranslation()
    const [uploadItems, setUploadItems] = useState<TFileUploadItem[]>([])
    const [totalItems, setTotalItems] = useState<number>(0)
    const [totalUploadSuccess, setTotalUploadSuccess] = useState<number>(-1)
    const prevTotalItems = usePrevious(totalItems)
    const refLock = useRef<boolean>(false)
    const uploadProps: UploadProps = {
      name: 'file',
      multiple: true,
      listType: 'picture',
      beforeUpload: () => false,
      showUploadList: false,
    }

    const handleUploadCompleted = () => {
      const index = findIndex(uploadItems, { error: true })
      if (index >= 0) return

      onUploadCompleted(
        uploadItems.reduce((prevValue: TUploadItem[], value) => {
          if (value.success) {
            prevValue.push({
              id: value.id,
              fileName: value.fileName,
              name: value.name,
              createdAt: value.createdAt,
              updatedAt: value.updatedAt,
              mimeType: value.mimeType,
            })
          }

          return prevValue
        }, []) as TUploadItem[]
      )
    }

    const handleRemove = (id: string) => {
      const index = findIndex(uploadItems, { id })
      if (index < 0) return

      setUploadItems(
        update(uploadItems, {
          $splice: [[index, 1]],
        })
      )

      setTotalItems(prevState => prevState - 1)
    }

    const handleError = (id: string) => {
      const index = findIndex(uploadItems, { id })
      if (index < 0) return

      setUploadItems(prevState =>
        update(prevState, {
          [index]: {
            error: { $set: true },
          },
        })
      )

      onError()
    }

    const handleUploading = (id: string) => {
      const index = findIndex(uploadItems, { id })
      if (index < 0) return

      setUploadItems(prevState =>
        update(prevState, {
          [index]: {
            uploading: { $set: true },
          },
        })
      )
    }

    const handleChange = (e: UploadChangeParam) => {
      if (refLock.current) return
      refLock.current = true
      const list: TFileUploadItem[] = []
      for (const item of e.fileList) {
        const uploadItem: TFileUploadItem = {
          id: item.uid,
          name: item.name,
          fileName: 'fileName',
          mimeType: '',
          createdAt: '',
          updatedAt: '',
          progress: 0,
          file: item.originFileObj as RcFile,
          success: false,
          error: false,
          uploading: false,
        }
        list.push(uploadItem)
      }
      setTotalUploadSuccess(e.fileList.length - 1)

      let newUploadItems = [...uploadItems, ...list]

      if (maxFileTotal && newUploadItems.length > maxFileTotal) {
        newUploadItems = newUploadItems.slice(
          newUploadItems.length - maxFileTotal
        )
      }

      setUploadItems(newUploadItems)

      setTotalItems(newUploadItems.length)

      onUploading()

      setTimeout(() => {
        refLock.current = false
      }, 1000)
    }

    const onUploadItemSuccess = (id: string, uploadItem: TUploadItem) => {
      const index = findIndex(uploadItems, { id })
      if (index < 0) return
      setUploadItems(prevState =>
        update(prevState, {
          [index]: {
            id: { $set: uploadItem.id },
            fileName: { $set: uploadItem.fileName },
            name: { $set: uploadItem.name },
            mimeType: { $set: uploadItem.mimeType },
            success: { $set: true },
            error: { $set: false },
          },
        })
      )
      setTotalUploadSuccess(prevState => prevState - 1)
    }

    useImperativeHandle(ref, () => ({
      uploadItems,
      setUploadItems,
    }))

    useEffect(() => {
      if (totalUploadSuccess < 0) {
        handleUploadCompleted()
      }
    }, [totalUploadSuccess])

    useEffect(() => {
      if (prevTotalItems && prevTotalItems > totalItems) {
        handleUploadCompleted()
      }
    }, [prevTotalItems, totalItems])

    return (
      <div className={clsx(classes['file-uploader'], className)}>
        <Dragger
          {...uploadProps}
          className={clsx(classes['dragger'], draggerClassName)}
          maxCount={maxFileUpload}
          onChange={handleChange}
          defaultFileList={[]}
          fileList={[]}
        >
          {children ?? (
            <>
              <p className={classes.icon}>
                <RiUploadCloudFill />
              </p>
              <p className="ant-upload-hint">
                {t('COMMON/DRAG_N_DROP', { accept: '(docx., .pdf)' })}
              </p>
            </>
          )}
        </Dragger>

        {uploadItems &&
          uploadItems.map(item => {
            return (
              <FileItem
                key={`file_${item.id}`}
                data={item}
                onUploadItemSuccess={onUploadItemSuccess}
                onRemove={handleRemove}
                onError={handleError}
                onUploading={handleUploading}
              />
            )
          })}
      </div>
    )
  }
)

export default FileUploader
