import {
  EClaimType,
  EExportStatus,
  ERelationship,
  EServiceType,
} from '@/constant'
import { FlagCambodia, FlagVietNam } from '@/assets/svg'
import { TCountryName, TCountryOption, TCurrency } from '@/types'
import { t } from 'i18next'

export const AuthErrors: { [key: string]: string } = {}

export const PNG = 'image/png'

export const JPG = {
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
}

export const WORD = {
  WORD: 'image/word',
  MSWORD: 'application/msword',
  APPWORD:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export const PDF = 'application/pdf'

export const XLS = {
  XLSOFFICE:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLSEXCEL: 'application/vnd.ms-excel',
}

export const PPTX =
  'application/vnd.openxmlformats-officedocument.presentationml.presentation'

export const COUNTRIES: TCountryOption[] = [
  {
    name: 'COMMON/COUNTRY_VN',
    value: 'vietnam',
    flag: FlagVietNam,
  },
  {
    name: 'COMMON/COUNTRY_KH',
    value: 'cambodia',
    flag: FlagCambodia,
  },
]

export const CURRENCY: Record<TCountryName, TCurrency> = {
  vietnam: { languageCode: 'vi-VN', currency: 'VND', symbol: 'đ' },
  cambodia: { languageCode: 'kh-KH', currency: 'KHR', symbol: 'USD' },
}

export const getExportStatusMapper = (): Record<EExportStatus, string> => ({
  [EExportStatus.REQUEST]: t('CLAIMS/EXPORT_STATUS_PENDING'),
  [EExportStatus.SUCCESS]: t('CLAIMS/EXPORT_STATUS_SUCCESS'),
  [EExportStatus.ERROR]: t('CLAIMS/EXPORT_STATUS_ERROR'),
  [EExportStatus.CANCEL]: t('CLAIMS/EXPORT_STATUS_CANCEL'),
})

export const getClaimTypeMapper = (): Record<EClaimType, string> => ({
  [EClaimType.REIMBURSEMENT]: t('CLAIMS/TYPE_REIMBURSEMENT'),
  [EClaimType.DIRECT_BILLING]: t('CLAIMS/TYPE_DB'),
})

export const getServiceTypeMapper = (): Record<
  EServiceType | string,
  string
> => ({
  [EServiceType.DENTAL]: t('COMPANY/SERVICE_TYPE/DENTAL'),
  [EServiceType.INPATIENT]: t('COMPANY/SERVICE_TYPE/INPATIENT'),
  [EServiceType.MATERNITY]: t('COMPANY/SERVICE_TYPE/MATERNITY'),
  [EServiceType.DEATH_DISABLEMENT]: t('COMPANY/SERVICE_TYPE/DEATH_DISABLEMENT'),
  [EServiceType.OUTPATIENT]: t('COMPANY/SERVICE_TYPE/OUTPATIENT'),
  [EServiceType.MEDICAL_EXPENSES_ACCIDENT]: t(
    'COMPANY/SERVICE_TYPE/MEDICAL_EXPENSES_ACCIDENT'
  ),
  [EServiceType.OTHER_BENEFIT]: t('COMPANY/SERVICE_TYPE/OTHER_BENEFIT'),
})

export const getRelationshipMapper = (): Record<
  ERelationship | string,
  string
> => ({
  [ERelationship.ASCENDANT]: t('CLAIMS/ASCENDANT'),
  [ERelationship.CHILD]: t('CLAIMS/CHILD'),
  [ERelationship.EMPLOYEE]: t('CLAIMS/EMPLOYEE'),
  [ERelationship.SPOUSE]: t('CLAIMS/SPOUSE'),
  [ERelationship.MEMBER]: t('CLAIMS/MEMBER'),
  [ERelationship.OTHER]: t('CLAIMS/OTHER'),
  [ERelationship.HUSBAND]: t('CLAIMS/HUSBAND'),
  [ERelationship.WIFE]: t('CLAIMS/WIFE'),
  [ERelationship.ADOPTED_CHILD]: t('CLAIMS/ADOPTED_CHILD'),
  [ERelationship.PARENT]: t('CLAIMS/PARENT'),
  [ERelationship.STEP_PARENT]: t('CLAIMS/STEP_PARENT'),
  [ERelationship.PARENT_IN_LAW]: t('CLAIMS/PARENT_IN_LAW'),
  [ERelationship.GRANDPARENT]: t('CLAIMS/GRANDPARENT'),
  [ERelationship.GRANDCHILD]: t('CLAIMS/GRANDCHILD'),
  [ERelationship.GUARDIAN]: t('CLAIMS/GUARDIAN'),
  [ERelationship.SIBLING]: t('CLAIMS/SIBLING'),
  [ERelationship.SIBLING_IN_LAW]: t('CLAIMS/SIBLING_IN_LAW'),
  [ERelationship.AUNT_UNCLE]: t('CLAIMS/AUNT_UNCLE'),
  [ERelationship.NIECE_NEPHEW]: t('CLAIMS/NIECE_NEPHEW'),
})

export const COLOR_CHART: string[] = [
  '#5073B8',
  '#EA557F',
  '#0F98AD',
  '#A166AB',
  '#0AB39C',
  '#6E1C74',
  '#5EC778',
  '#56CCF2',
  '#21965D',
  '#2F80ED',
  '#F16548',
  '#F1963A',
]

export const LIMIT_FOR_MASTER_DATA = 1000
