/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */

import '@sas/components-fe/dist/styles/variables.css'
import '@sas/components-fe/dist/styles/index.less'
import '@sas/components-fe/dist/index.css'
import './theme/colors.css'
import './theme/font.less'

import App from './App'
import { createRoot } from 'react-dom/client'
import { appConfig } from '@/config'

if (appConfig.NODE_ENV === 'development' && appConfig.MSW === 'true') {
  import('./msw/browser').then(({ worker }) => {
    worker.start()
  })
}

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(<App />)
}
