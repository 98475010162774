import { HttpService } from './http-service'
import { REST_API } from '@/config'
import { TUserPermissionResponse, TUserProfileResponse } from '@/types/models'

export const getPermission = async (): Promise<TUserPermissionResponse> => {
  const route: string = REST_API.GET_PERMISSION.uri

  return HttpService.get<TUserPermissionResponse>(route)
}

export const getProfile = async (): Promise<TUserProfileResponse> => {
  const route: string = REST_API.GET_PROFILE.uri

  return HttpService.get<TUserProfileResponse>(route)
}
