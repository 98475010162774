import DashboardV2 from '@/pages/DashboardV2'
import { RiDashboardLine } from 'react-icons/ri'

import type { TRoute } from '@/types'

export const DashboardV2Routes: TRoute[] = [
  {
    path: '/',
    component: DashboardV2,
    breadcrumb: 'BREADCRUMB/DASHBOARD',
    page: 'accessible',
    showInMenu: true,
    menuIcon: <RiDashboardLine fontSize={24} />,
    menuLabel: 'BREADCRUMB/DASHBOARD',
  },
]
