import classes from './ExportCompleteModal.module.less'
import { BackgroundCompleteModal } from '@/assets'
import { Modal } from '@sas/components-fe'
import { Typography } from '@sas/components-fe'
import { useTranslation } from 'react-i18next'

type TExportCompleteModalProps = {
  visible: boolean
  onOk: () => void
  onCancel: () => void
}

const ExportCompleteModal = ({
  visible,
  onOk,
  onCancel,
}: TExportCompleteModalProps) => {
  const { t } = useTranslation()
  return (
    <Modal
      closable={false}
      className={classes['export-complete-modal']}
      open={visible}
      title={null}
      width={400}
      cancelText={t('EXPORT_COMPLETE_MODAL/DO_IT_LATER')}
      cancelButtonProps={{ className: classes['action-btn'] }}
      onOk={onOk}
      onCancel={onCancel}
      okButtonProps={{ className: classes['action-btn'] }}
      okText={t('EXPORT_COMPLETE_MODAL/DO_IT_NOW')}
    >
      <img
        className={classes['header-bg']}
        alt="export complete modal background"
        src={BackgroundCompleteModal}
        width="100%"
      ></img>
      <Typography typoType="paragraph">
        {t('EXPORT_COMPLETE_MODAL/HURA_DATA_EXPORT_IS_COMPLETE')}
      </Typography>
      <Typography typoType="paragraph">
        {t('EXPORT_COMPLETE_MODAL/THE_DATA_FILE_IS_READY_MESSAGE')}
      </Typography>
    </Modal>
  )
}

export default ExportCompleteModal
