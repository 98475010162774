export const API_URL = import.meta.env.FHVI_API_URL
export const IMAGE_URL = import.meta.env.FHVI_IMAGE_URL
export const PARTNER_URL_LOGO = import.meta.env.FHVI_PARTNER_URL_LOGO
export const MEMBER_PORTAL_IMAGE_URL = import.meta.env
  .FHVI_MEMBER_PORTAL_IMAGE_URL
export const CLAIMS_PORTAL_IMAGE_URL = import.meta.env
  .FHVI_CLAIMS_PORTAL_IMAGE_URL
export const REALM_NAME = import.meta.env.FHVI_REALM_NAME
export const PORTAL_NAME = import.meta.env.FHVI_PORTAL_NAME
export const KEYCLOAK_URL = import.meta.env.FHVI_KEYCLOAK_URL
export const KEYCLOAK_REALM = import.meta.env.FHVI_KEYCLOAK_REALM
export const KEYCLOAK_CLIENT_ID = import.meta.env.FHVI_KEYCLOAK_CLIENT_ID
export const NODE_ENV = import.meta.env.FHVI_NODE_ENV
export const MSW = import.meta.env.FHVI_MSW
export const WS_ENDPOINT = import.meta.env.FHVI_API_URL
export const LIMIT_TABLE_SIZE = +import.meta.env.FHVI_LIMIT_TABLE_SIZE || 10
