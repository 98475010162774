export const REST_API_CLAIMS = {
  GET_CLAIM_DETAIL: {
    uri: 'partner-portal/v1/claim-detail/:claimNo',
    method: 'GET',
  },
  GET_GENERAL_INFORMATION: {
    uri: 'partner-portal/v1/claims/:id/general-info',
    method: 'GET',
  },
  GET_BREAKDOWN: {
    uri: 'partner-portal/v1/claims/:id/payment-breakdown',
    method: 'GET',
  },
  GET_BREAKDOWN_V2: {
    uri: 'partner-portal/v2/claims/:id/payment-breakdown',
    method: 'GET',
  },
  GET_CLAIM_HISTORY: {
    uri: 'partner-portal/v1/:memberNumber/:suffix/claims-history',
    method: 'GET',
  },
  GET_MEMBER_POLICY_LIMIT: {
    uri: 'partner-portal/v1/claims/:memberCard/:memberCardPad/policy-limit',
    method: 'GET',
  },
  UPDATE_ATTACHMENT: {
    uri: 'partner-portal/v1/claim-document',
    method: 'POST',
  },
  GET_ATTACHMENT_MOBILE: {
    uri: 'partner-portal/v1/claim-document/mobile/:claimNo',
    method: 'GET',
  },
  GET_ATTACHMENT_EXTERNAL: {
    uri: 'partner-portal/v1/claim-document/external/:claimNo',
    method: 'GET',
  },
  GET_CLAIMS: {
    uri: 'partner-portal/v1/claims',
    method: 'GET',
  },
  GET_CLAIM_BY_ID: {
    uri: 'partner-portal/v1/claims/:claimNo',
    method: 'GET',
  },
  CREATE_EXPORT_PENDING_CLAIMS: {
    uri: 'partner-portal/v1/export/claims',
    method: 'POST',
  },
  GET_PAYMENT_NOTICE: {
    uri: 'partner-portal/v1/claims/:id/payment-notice',
    method: 'GET',
  },
  CREATE_EXPORT_CLAIM_GENERAL: {
    uri: 'partner-portal/v1/export/claims-general',
    method: 'POST',
  },
}
