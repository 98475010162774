import * as React from 'react'
import { ConfigProvider } from 'antd'
import { Lang } from '@/constant'
import { MainLayout } from './layout'
import { Route, Routes } from 'react-router'
import { flatten } from '@sas/components-fe'
import { locale } from 'dayjs'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { withCustomErrorBoundary } from '@/HOC'

import localeEN from 'antd/es/locale/en_US'
import localeVI from 'antd/es/locale/vi_VN'

import { ClaimsRoutes } from '@/routes/Claims'
import { CompanyRoutes } from '@/routes/Company'
import { DashboardV2Routes } from '@/routes/DashboardV2'
import { NotFoundRoutes } from '@/routes/NotFound'
import { SharedDocumentsRoutes } from '@/routes/SharedDocuments'
import { SignInRoutes } from '@/routes/SignIn'
import { SignOutRoutes } from '@/routes/SignOut'

import AuthWrapper from '@/components/AuthWrapper'
import CustomNavigate from '@/components/CustomNavigate'
import Unauthorized from '@/components/Unauthorized'

import type { TRoute } from '@/types'

export const MainLayoutRoutes: TRoute = {
  component: MainLayout,
  path: '/',
  page: 'accessible',
  children: [
    ...DashboardV2Routes,
    ...CompanyRoutes,
    ...ClaimsRoutes,
    ...NotFoundRoutes,
    ...SharedDocumentsRoutes,
  ],
}

export const routes: TRoute[] = [SignInRoutes, SignOutRoutes, MainLayoutRoutes]

export const flattenRoutes = flatten(routes)

const renderRoute = (routes: TRoute[]): React.ReactNode[] =>
  routes.map(
    ({
      redirectTo,
      children,
      component: Component,
      path,
      page,
      permission,
    }) => {
      const AuthComponent = () => (
        <AuthWrapper
          page={page}
          permission={permission}
          fallback={<Unauthorized />}
        >
          <Component />
        </AuthWrapper>
      )

      const CustomComponent = withCustomErrorBoundary(
        path !== '*' ? AuthComponent : Component
      )

      if (children && children.length > 0) {
        return (
          <Route key={path} path={path} element={<CustomComponent />}>
            {redirectTo && (
              <Route
                index
                element={<CustomNavigate to={redirectTo} replace />}
              />
            )}
            {renderRoute(children)}
          </Route>
        )
      }
      return (
        <Route
          key={path}
          path={path}
          element={
            redirectTo ? (
              <CustomNavigate to={redirectTo} replace />
            ) : (
              <CustomComponent />
            )
          }
        />
      )
    }
  )

export const AppRoutes = () => {
  const {
    i18n: { language },
  } = useTranslation()

  useEffect(() => {
    locale(language)
  }, [language])

  return (
    <ConfigProvider locale={language === Lang.VI ? localeVI : localeEN}>
      <Routes>{renderRoute(routes)}</Routes>
    </ConfigProvider>
  )
}
