import { Button } from '@sas/components-fe'
import { Link, useNavigate } from 'react-router-dom'
import { Result, Space } from 'antd'
import { RootPaths } from '@/constant'
import { useTranslation } from 'react-i18next'

const Unauthorized = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Result
      status="403"
      title="403"
      subTitle={t('COMMON/SORRY_YOU_ARE_NOT_AUTHORIZED_TO_ACCESS_THIS_PAGE')}
      extra={
        <Space>
          <Button type="default" onClick={() => navigate(-1)}>
            {t('COMMON/GO_BACK')}
          </Button>
          <Button type="primary">
            <Link to={RootPaths.DASHBOARD}>{t('COMMON/BACK_HOME')}</Link>
          </Button>
        </Space>
      }
    />
  )
}

export default Unauthorized
