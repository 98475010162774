import { REST_API } from '@/config'
import { TApiError } from '@/api/errors'
import { TQueryOptions } from '@/types/api'
import { TUserPermissionResponse, TUserProfileResponse } from '@/types/models'
import { getPermission, getProfile } from '@/api'
import { useQuery } from 'react-query'

export function usePermission(request?: TQueryOptions) {
  return useQuery<TUserPermissionResponse, TApiError>(
    [REST_API.GET_PERMISSION],
    getPermission,
    request?.queryConfig
  )
}

export function useGetProfile(request?: TQueryOptions) {
  return useQuery<TUserProfileResponse, TApiError>(
    [REST_API.GET_PROFILE],
    getProfile,
    request?.queryConfig
  )
}
