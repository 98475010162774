import * as React from 'react'
import classes from './FilePreview.module.less'
import clsx from 'clsx'
import { AuthImage } from '../AuthImage'
import { BsBoxArrowUpRight } from 'react-icons/bs'
import {
  Button,
  downloadAuthMedia,
  openAuthMedia,
  openNotification,
  useAuthMedia,
} from '@sas/components-fe'
import { LARGEPDF } from '@/assets'
import {
  PDF,
  copyContent,
  getMediaLink,
  keycloakClient,
  mapFileTypeToIcon,
} from '@/utils'
import {
  RiAddFill,
  RiDownloadLine,
  RiFileCopyLine,
  RiSubtractLine,
  RiZoomOutLine,
} from 'react-icons/ri'
import { TPortalTypes, TUploadItem } from '@/types'
import { Typography } from '@sas/components-fe'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type TFilePreviewProps = {
  open?: boolean
  currentFile?: TUploadItem | null
  selectPortalType: TPortalTypes
  onClose?: () => void
  onOpen?: () => void
}

export default function FilePreview({
  open,
  currentFile,
  selectPortalType,
  onClose,
}: TFilePreviewProps) {
  const { t } = useTranslation()
  const { mediaUrl } = useAuthMedia(
    getMediaLink(currentFile!?.fileName, selectPortalType),
    keycloakClient.token
  )

  const [isShowingPdf, setIsShowingPdf] = useState(false)
  const [zoomable, setZoomable] = useState(false)
  const [pos, setPos] = useState({ scale: 1 })
  const ref = useRef<HTMLImageElement>(null)

  const handleClosePreview = () => {
    // setIsOpenPreview(false)
    // setCurrentFile(undefined)
    onClose?.()
    setIsShowingPdf(false)
    setPos({ scale: 1 })
  }

  const onScroll = (e: React.WheelEvent<HTMLDivElement>) => {
    if (!zoomable) {
      return
    }

    let { deltaY } = e

    if (deltaY < 0) {
      deltaY += 40
    } else {
      deltaY -= 40
    }

    let delta = deltaY * -0.01

    if (pos.scale < -delta) {
      delta = 0
    }
    const newScale = Math.abs(pos.scale + delta)

    setPos({
      scale: newScale,
    })
  }

  useEffect(() => {
    if (open) {
      setZoomable(currentFile?.mimeType !== PDF)
    } else {
      setIsShowingPdf(false)
      setPos({ scale: 1 })
    }
  }, [open])

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (event.target instanceof HTMLDivElement) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleClosePreview()
        }
      }
    }

    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  if (currentFile == null) {
    return null
  }

  return open ? (
    <div className={classes.overlay}>
      <div className={classes.heading}>
        <div className={classes.left}>
          <div className={classes.content}>
            <AuthImage
              src={mapFileTypeToIcon(currentFile.mimeType)}
              alt="img-icon"
            />
            <div className={classes.info}>
              <Typography
                className={classes.name}
                colorType="text"
                colorVariant={100}
                fontWeight={600}
                fontSize="1rem"
              >
                {currentFile.name}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.right}>
          <Button
            type="text"
            shape="circle"
            onClick={() =>
              openNotification({
                notificationType: 'Info',
                message: t('COMMON/LINK_COPIED'),
              })
            }
          >
            <RiFileCopyLine
              color="white"
              onClick={() => copyContent(mediaUrl)}
            />
          </Button>
          <Button
            type="text"
            shape="circle"
            onClick={() =>
              downloadAuthMedia(
                mediaUrl,
                currentFile.name,
                keycloakClient.token!
              )
            }
          >
            <RiDownloadLine color="white" />
          </Button>
          {/* <Button type="text" shape="circle" danger>
            @TODO implement later
            <RiDeleteBin7Line />
          </Button> */}
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes['preview-image']}>
          <div
            onWheelCapture={onScroll}
            style={{
              transformOrigin: 'center',
              transform: `scale(${pos.scale})`,
            }}
          >
            <AuthImage
              src={
                currentFile.mimeType !== PDF || isShowingPdf
                  ? mediaUrl
                  : LARGEPDF
              }
              alt="preview"
              className={clsx(
                classes['image-inside'],
                currentFile.mimeType === PDF && !isShowingPdf && classes.pdf
              )}
              ref={ref}
            />
          </div>
          {!isShowingPdf && currentFile.mimeType === PDF && (
            <Button
              onClick={e => {
                e.stopPropagation()
                openAuthMedia(
                  getMediaLink(currentFile!?.fileName, selectPortalType),
                  keycloakClient?.token!
                )
              }}
            >
              <div className={classes['button-flex']}>
                Open the file
                <BsBoxArrowUpRight />
              </div>
            </Button>
          )}
        </div>

        {zoomable && (
          <div className={classes.zoom}>
            <Button type="text" shape="circle">
              <RiSubtractLine color="white" />
            </Button>
            <Button type="text" shape="circle">
              <RiZoomOutLine color="white" />
            </Button>
            <Button type="text" shape="circle">
              <RiAddFill color="white" />
            </Button>
          </div>
        )}
      </div>
    </div>
  ) : null
}
