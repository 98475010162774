import { Button, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { EExportStatus } from '@/constant'
import { RiDownloadLine } from 'react-icons/ri'
import { StatusItem } from '@/components'
import { TExportResponse } from '@/types/api'
import { downloadAuthMedia, formatDate } from '@sas/components-fe'
import { getExportStatusMapper, getMediaLink, keycloakClient } from '@/utils'
import { t } from 'i18next'

export const getExportHistoryColumns = () =>
  [
    {
      title: t('CLAIMS/EXPORT_NAME'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: t('CLAIMS/EXPORTED_AT'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: formatDate,
      align: 'center',
    },
    {
      title: t('CLAIMS/STATUS'),
      dataIndex: 'status',
      key: 'status',
      render: (value: EExportStatus) => (
        <StatusItem
          value={value}
          label={getExportStatusMapper()[value] || value}
        />
      ),
      align: 'center',
    },
    {
      title: t('COMMON/ACTION'),
      align: 'center',
      key: 'action',
      render: (_value, record) => {
        return record.status === EExportStatus.SUCCESS ? (
          <Tooltip title={t('COMMON/DOWNLOAD')} destroyTooltipOnHide>
            <Button
              type="text"
              shape="circle"
              size="small"
              onClick={() =>
                downloadAuthMedia(
                  getMediaLink(record.filename),
                  record.filename,
                  keycloakClient.token!
                )
              }
            >
              <RiDownloadLine size={18} />
            </Button>
          </Tooltip>
        ) : null
      },
    },
  ] satisfies ColumnsType<TExportResponse>
