export const RootPaths = {
  DASHBOARD: '/',
  SEARCH_CLAIMS: '/search-claims',
  COMPANY: '/company',
  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',
  SHARED_DOCUMENTS: '/shared-documents',
  NOTIFICATIONS: '/notifications',
  REPORTS: '/reports',
  SMS: '/sms',
  TEMPLATES: '/templates',
  CLAIMS: '/claims',
  UPDATES: '/updates',
  PCLA: '/pcla',
}

export const ClaimsPaths = {
  CLAIM_EXPORT_HISTORY: `${RootPaths.CLAIMS}/export-history`,
  CLAIM_DETAIL: `${RootPaths.CLAIMS}/:claimId`,
  CLAIM_DETAIL_OVERVIEW: `${RootPaths.CLAIMS}/:claimId/overview`,
  CLAIM_DETAIL_CLAIM_BREAKDOWN: `${RootPaths.CLAIMS}/:claimId/claim-breakdown`,
  CLAIM_DETAIL_GENERAL_INFORMATION: `${RootPaths.CLAIMS}/:claimId/general-information`,
  CLAIM_DETAIL_ATTACHMENT: `${RootPaths.CLAIMS}/:claimId/attachment`,
  CLAIM_DETAIL_CLAIM_HISTORY: `${RootPaths.CLAIMS}/:claimId/claim-history`,
  CLAIM_DETAIL_EXPORT_HISTORY: `${RootPaths.CLAIMS}/:claimId/export-history`,
}

export const CompanyPaths = {
  COMPANY_DETAIL: `${RootPaths.COMPANY}/:companyId`,
  COMPANY_EDIT_PROFILE: `${RootPaths.COMPANY}/:companyId/edit-profile`,
}
