import classes from './AppAlert.module.less'
import { Alert, AlertProps } from 'antd'

export type TAppAlertProps = AlertProps

const AppAlert = (props: AlertProps) => {
  return (
    <div className={classes['app-alert']}>
      <Alert {...props} />
    </div>
  )
}

export default AppAlert
