import classes from './ExportHistory.module.less'
import { ExportEvent } from '@/constant'
import { Table, formatNumber } from '@sas/components-fe'
import { appConfig } from '@/config'
import { getExportHistoryColumns } from './ExportHistory.config'
import { useEffect, useState } from 'react'
import { useGetExportHistory } from '@/api'
import { useTranslation } from 'react-i18next'
import { useWebsocket } from '@/hooks/useWebsocket'

const ExportHistory = () => {
  const { t } = useTranslation()
  const [exportHistoryPage, setExportHistoryPage] = useState(1)
  const { exportEvent } = useWebsocket()

  const {
    data: exportHistory,
    isLoading: exportHistoryLoading,
    isFetching: exportHistoryFetching,
    refetch: onRefetchHistory,
  } = useGetExportHistory(
    {
      page: exportHistoryPage,
      limit: appConfig.LIMIT_TABLE_SIZE,
      functionName: 'ClaimDetail',
    },
    true
  )

  useEffect(() => {
    if (!exportEvent) {
      return
    }

    if (
      exportEvent.eventName ===
        ExportEvent.CLAIM_PARTNER_EXPORT_DETAIL_SUCCESS ||
      exportEvent.eventName === ExportEvent.REFETCH_DATA
    ) {
      onRefetchHistory()
    }
  }, [exportEvent])

  return (
    <div className={classes.ctn}>
      <div className={classes.title}>
        <div className={classes['title-text']}>
          {t('CLAIMS/EXPORT_HISTORY')}
        </div>
        <div className={classes.status}></div>
      </div>
      <Table
        columns={getExportHistoryColumns()}
        dataSource={exportHistory?.data}
        page={exportHistoryPage}
        setPage={() => setExportHistoryPage(prev => prev + 1)}
        showColumnNumber={true}
        total={exportHistory?.total}
        loading={exportHistoryLoading || exportHistoryFetching}
        showTotal={(total: number, range: number[]) =>
          t('COMMON/DISPLAYING_OF_ITEMS', {
            start: range[0],
            end: range[1],
            total: formatNumber(total, { allowZero: true }),
          })
        }
        rowKey="id"
      />
    </div>
  )
}

export default ExportHistory
