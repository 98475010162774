import { REST_API_USER, REST_API_CLAIMS } from '@/api'

export const REST_API = {
  ...REST_API_USER,
  REST_API_CLAIMS,
}

export const buildRestUrl = (url: string, data: any) => {
  const keys = Object.keys(data)

  keys.forEach((key: string) => {
    const paramName = `:${key}`
    if (url.indexOf(paramName) >= 0) {
      url = url.replace(paramName, data[key])
    }
  })

  return url
}
