export const REST_API_UPLOAD = {
  UPLOAD_DOCUMENT: {
    uri: 'claims-portal/v1/upload',
    method: 'POST',
  },
  GET_DOCUMENT: {
    uri: 'claims-portal/v1/upload/:id',
    method: 'GET',
  },
}
