import {
  REST_API_EXPORT,
  TApiError,
  cancelExportClaims,
  checkExportRequest,
  createExportClaimDetail,
  getExportHistory,
} from '@/api'
import {
  TCheckExportRequestPayload,
  TCheckExportRequestResponse,
  TCreateExportClaimDetailPayload,
  TExportPayload,
  TExportResponse,
  TPaginationResponse,
} from '@/types'

import { useMutation, useQuery } from 'react-query'

export function useGetExportHistory(payload: TExportPayload, enabled: boolean) {
  return useQuery<TPaginationResponse<TExportResponse[]>, TApiError>(
    [REST_API_EXPORT.GET_EXPORT_HISTORY, payload],
    () => getExportHistory(payload),
    { enabled }
  )
}

export function useCreateExportClaimDetail() {
  return useMutation<void, TApiError, TCreateExportClaimDetailPayload>(
    async (payload: TCreateExportClaimDetailPayload) =>
      createExportClaimDetail(payload)
  )
}

export function useCancelExportClaimList() {
  return useMutation<void, TApiError, string>(async (id: string) =>
    cancelExportClaims(id)
  )
}

export function useCheckExportRequest(payload: TCheckExportRequestPayload) {
  return useQuery<TCheckExportRequestResponse, TApiError>(
    [REST_API_EXPORT.CHECK_EXPORT_REQUEST, payload],
    () => checkExportRequest(payload)
  )
}
