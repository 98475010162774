import { EClaimStatus, EExportStatus } from '@/constant'
import { useMemo } from 'react'

import { Space } from 'antd'
import {
  TTagColor,
  TTagType,
} from '@sas/components-fe/dist/components/atoms/Tag/Tag'
import { Tag } from '@sas/components-fe'
import { t } from 'i18next'

type TStatus = EClaimStatus | EExportStatus | string
type TStatusItemProps = {
  value: TStatus
  label: string
  type?: TTagType
}

const getStatusNameMapper = (): Record<TStatus, string> => ({
  [EClaimStatus.PENDING_REVIEW]: t('CLAIMS/STATUS_PENDING_REVIEW'),
  [EClaimStatus.PENDING_INSURER]: t('CLAIMS/STATUS_PENDING_INSURER'),
  [EClaimStatus.PENDING_DOCUMENT]: t('CLAIMS/STATUS_PENDING_DOCUMENT'),
  [EClaimStatus.PENDING_SETTLEMENT]: t('CLAIMS/STATUS_PENDING_SETTLEMENT'),
  [EClaimStatus.PENDING_REVISION]: t('CLAIMS/STATUS_PENDING_REVISION'),
  [EClaimStatus.PENDING_HOD]: t('CLAIMS/STATUS_PENDING_HOD'),
  [EClaimStatus.PARTIAL_APPROVED]: t('CLAIMS/STATUS_PARTIAL_APPROVED'),
  [EClaimStatus.APPROVED]: t('CLAIMS/STATUS_APPROVED'),
  [EClaimStatus.REJECTED]: t('CLAIMS/STATUS_REJECTED'),
  [EClaimStatus.CANCEL]: t('CLAIMS/STATUS_CANCEL'),
  [EClaimStatus.RETURN]: t('CLAIMS/STATUS_RETURN'),
  [EClaimStatus.SUBMITTED]: t('CLAIMS/STATUS_SUBMITTED'),
  [EClaimStatus.WAITING_FOR_TRANSFER]: t('CLAIMS/STATUS_WAITING_FOR_TRANSFER'),
  [EClaimStatus.TRANSFERRED]: t('CLAIMS/STATUS_TRANSFERRED'),
  [EClaimStatus.TRANSFER_TO_INSURER]: t('CLAIMS/STATUS_TRANSFER_TO_INSURER'),
  [EClaimStatus.SETTLED]: t('CLAIMS/STATUS_SETTLED'),
})

export function getStatusTagColor(
  status: EClaimStatus | EExportStatus | string
): TTagColor | undefined {
  switch (status) {
    case EClaimStatus.PENDING_REVIEW:
    case EClaimStatus.WAITING_FOR_TRANSFER:
    case EExportStatus.REQUEST:
      return 'yellow'

    case EClaimStatus.PENDING_DOCUMENT:
    case EClaimStatus.PARTIAL_APPROVED:
    case EClaimStatus.TRANSFER_TO_INSURER:
      return 'purple'

    case EClaimStatus.PENDING_HOD:
      return 'blue'

    case EClaimStatus.PENDING_REVISION:
      return 'grey'

    case EClaimStatus.PENDING_SETTLEMENT:
    case EClaimStatus.REJECTED:
    case EExportStatus.ERROR:
      return 'red'
    case EClaimStatus.PENDING_INSURER:
      return 'yellow'
    case EClaimStatus.PENDING_PAYMENT:
      return 'yellow'
    case EClaimStatus.PENDING_PREMIUM:
      return 'yellow'
    case EClaimStatus.SETTLED:
      return 'green'
    case EClaimStatus.CANCEL:
      return 'red'
    case EClaimStatus.RETURN:
      return 'red'
    case EClaimStatus.WAITING_FOR_TRANSFER:
      return 'yellow'
    case EClaimStatus.TRANSFER_TO_INSURER:
      return 'yellow'
    case EExportStatus.SUCCESS:
    case EClaimStatus.APPROVED:
    case EClaimStatus.TRANSFERRED:
      return 'green'
  }
}

const StatusItem = ({
  value,
  label,
  type = 'minimalism',
}: TStatusItemProps) => {
  const statusNameMapper = useMemo(getStatusNameMapper, [])

  return (
    <Space>
      <Tag color={getStatusTagColor(value)} type={type}>
        {statusNameMapper[label] || label}
      </Tag>
    </Space>
  )
}

export default StatusItem
