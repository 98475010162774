import { AxiosError } from 'axios'
import { RootPaths } from '@/constant/path'
import { TMessageError } from '@/types/common'
import { TranslationKey } from '@/types/i18n'
import { keycloakClient } from '@/utils'
import { openNotification } from '@sas/components-fe'
import { t } from 'i18next'

export class ApiError extends Error {
  message: string
  status: number
  errors: TMessageError[]
  data?: Object

  constructor(message: string, status: number = 0, data?: Object) {
    super('')
    this.message = message
    this.name = 'ApiError'
    this.status = status
    this.data = data
  }
}

export class ApiErrorForm extends Error {
  errors: TMessageError[]
  status: number

  constructor(message: TMessageError[], status: number = 0) {
    super('')
    this.errors = message
    this.name = 'ApiErrorForm'
    this.status = status
  }
}

export type TApiError = ApiError | ApiErrorForm

export const HandleResponseError = (
  error: AxiosError<{ message: string | TMessageError[]; data: Object }>
) => {
  if (error.response?.status === 401) {
    keycloakClient.logout().then(() => {
      history.pushState({}, '', RootPaths.SIGN_IN)
    })
  }

  if (typeof error?.response?.data?.message === 'object') {
    const firstErrKey = Object.keys(error?.response?.data?.message[0])
    const firstErrValue =
      error?.response?.data?.message[0]?.[firstErrKey[0]]?.[0]

    openNotification({
      notificationType: 'Error',
      message: firstErrValue,
    })

    throw new ApiErrorForm(
      error.response.data.message as TMessageError[],
      error.response?.status
    )
  }

  if (typeof error?.response?.data?.message === 'string') {
    openNotification({
      notificationType: 'Error',
      message: t(
        error?.response?.data?.message as TranslationKey,
        error?.response?.data?.data
      ),
    })

    throw new ApiError(
      error.response.data.message,
      error.response?.status,
      error?.response?.data?.data
    )
  }

  openNotification({
    notificationType: 'Error',
    message: 'Unknown',
  })

  throw new ApiError('Unknown', error.response?.status || 400)
}
