export const REST_API_USER = {
  GET_PERMISSION: {
    uri: 'user-portal/v2/users/permission',
    method: 'GET',
  },
  GET_PROFILE: {
    uri: 'auth/v1/profile',
    method: 'GET',
  },
}
