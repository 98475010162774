import { registerSW } from 'virtual:pwa-register'

const updateSW = registerSW({
  onNeedRefresh() {
    if (confirm('New content is available. Reload to update?')) {
      updateSW() // Force update the SW
    }
  },
  onOfflineReady() {
    console.log('App is ready to work offline.')
  },
})

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/sw.js', { scope: '/' })
      .then(registration => {
        console.log('Service Worker registered:', registration)

        // Check if there's a waiting service worker
        if (registration.waiting) {
          console.log('New Service Worker is waiting. Activating it now...')
          registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        }

        // Listen for updates to the Service Worker
        registration.onupdatefound = () => {
          const installingWorker = registration.installing
          if (installingWorker) {
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // Notify user about new content or reload to activate new SW
                  console.log('New content is available. Reloading now...')
                  window.location.reload()
                } else {
                  console.log('Content is cached for offline use.')
                }
              }
            }
          }
        }
      })
      .catch(error => {
        console.error('Error registering Service Worker:', error)
      })

    // Optional: Listen for controller change to ensure activation
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      console.log('Controller changed. Reloading the page...')
      window.location.reload()
    })
  })
}
