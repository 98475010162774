import './config/i18n'
import './registerSW'
import { AppRoutes } from './App.route'
import { AuthLoader } from '@/hooks/useAuth'
import {
  NotificationProvider,
  OverlayLoader,
  getThemeConfig,
} from '@sas/components-fe'
import { QueryClientProvider } from 'react-query'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Suspense } from 'react'
import { SuspenseRouter } from '@/HOC'
import { ConfigProvider as UiProvider, notification } from 'antd'
import { WebsocketProvider } from '@/hooks'
import { keycloakClient, onEventKeyCloak, onTokensKeyCloak } from './utils'
import { queryClient } from '@/config'

notification.config({
  maxCount: 1,
})

const App = () => {
  return (
    <ReactKeycloakProvider
      authClient={keycloakClient}
      onEvent={onEventKeyCloak}
      onTokens={onTokensKeyCloak}
      LoadingComponent={<OverlayLoader text="Authenticating..." />}
      initOptions={{
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/silent-check-sso.html',
      }}
    >
      <NotificationProvider preventDuplicate />
      <Suspense>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <SuspenseRouter>
            <AuthLoader>
              <UiProvider theme={getThemeConfig()}>
                <WebsocketProvider>{AppRoutes()}</WebsocketProvider>
              </UiProvider>
            </AuthLoader>
          </SuspenseRouter>
        </QueryClientProvider>
      </Suspense>
    </ReactKeycloakProvider>
  )
}

export default App
