/* eslint-disable @typescript-eslint/no-unused-vars */
import Keycloak from 'keycloak-js'
import {
  AuthClientError,
  AuthClientEvent,
  AuthClientTokens,
} from '@react-keycloak/core/lib/types'
import { appConfig } from '@/config'

export const keycloakClient = new Keycloak({
  url: `${appConfig.KEYCLOAK_URL}/auth`,
  realm: appConfig.KEYCLOAK_REALM,
  clientId: appConfig.KEYCLOAK_CLIENT_ID,
})

export const onEventKeyCloak = (
  eventType: AuthClientEvent,
  error?: AuthClientError
) => {}

export const onTokensKeyCloak = (tokens: AuthClientTokens) => {}
