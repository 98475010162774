import axios from 'axios'
import { JPG, PDF, PNG, PPTX, WORD, XLS } from './constants'
import { TBasicObject, TPortalTypes } from '@/types'
import { TJPG, TMULTIFILE, TPDF, TPNG, TPPTX, TWORD, TXLS } from '@/assets'
import { appConfig } from '@/config'
import { isEmpty } from 'lodash'

export const saveStorage = (key: string, value: string) => {
  localStorage.setItem(key, value)
}

export const loadAccount = (): {
  identifier: string
  password: string
} | null => {
  let str = getStorage('RM')
  if (str) {
    str = window.atob(str)
    const temp = str.split('_')
    return temp.length === 2 ? { identifier: temp[0], password: temp[1] } : null
  }

  return null
}

export const removeStorage = (key: string) => {
  localStorage.removeItem(key)
}

export const getStorage = (key: string): string | null => {
  return localStorage.getItem(key)
}

export const parseUrl = (url: string) => {
  const regex = /[?&]([^=#]+)=([^&#]*)/g
  const params: { [key: string]: string } = {}
  let match
  while ((match = regex.exec(url))) {
    if (match[1]) {
      params[match[1]] = match[2] || ''
    }
  }
  return params
}

export const safeParseJsonType = <T extends {}>(data: string): T => {
  return JSON.parse(data)
}

export const setRedirectUrl = (): string => {
  const { pathname } = window.location

  return `redirect_url=${pathname}`
}

export const getRedirectUrl = (): string => {
  const urlSearchParams = new URLSearchParams(window.location.search)

  return urlSearchParams.get('redirect_url') || '/'
}

export const isImageType = (type: string): boolean => {
  switch (type) {
    case PNG:
    case JPG.JPEG:
    case JPG.JPG:
      return true
    default:
      return false
  }
}

export const mapFileTypeToIcon = (type: string): string => {
  switch (type) {
    case PNG:
      return TPNG
    case JPG.JPEG:
    case JPG.JPG:
      return TJPG
    case WORD.WORD:
    case WORD.MSWORD:
    case WORD.APPWORD:
      return TWORD
    case PDF:
      return TPDF
    case XLS.XLSOFFICE:
    case XLS.XLSEXCEL:
      return TXLS
    case PPTX:
      return TPPTX
    default:
      return TMULTIFILE
  }
}

export const getMediaLink = (name: string, type?: TPortalTypes) => {
  if (type === 'MEMBER_PORTAL') {
    return `${appConfig.MEMBER_PORTAL_IMAGE_URL}${name}`
  }

  if (type === 'CLAIMS_PORTAL') {
    return `${appConfig.CLAIMS_PORTAL_IMAGE_URL}${name}`
  }

  if (type === 'PARTNER_URL_LOGO') {
    return `${appConfig.PARTNER_URL_LOGO}${name}`
  }

  return `${appConfig.IMAGE_URL}${name}`
}

export const downloadURI = (uri: string, name: string, token: string): void => {
  axios({
    url: uri,
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}

export const copyContent = (value: string) => {
  const el = document.createElement('textarea')
  el.value = value
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const downloadURIByDom = (uri: string, name: string): void => {
  var link = document.createElement('a')
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const removeFalsyValueFromObj = (obj: TBasicObject) => {
  Object.keys(obj).forEach(key => {
    if (!obj[key] || !(obj[key] as string).trim()) {
      delete obj[key]
    } else if (isEmpty(obj[key])) {
      delete obj[key]
    }
  })
  return obj
}
