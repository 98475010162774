import NotFound from '@/pages/NotFound'

import type { TRoute } from '@/types/common/route'

export const NotFoundRoutes: TRoute[] = [
  {
    path: '*',
    component: NotFound,
    children: [],
  },
]
