import SideBar from '@/components/SideBar'
import classes from './MainLayout.module.less'
import { AppAlert, ExportCompleteModal, SelectCountry } from '@/components'
import { Breadcrumb, BreadcrumbProps, Layout } from 'antd'
import { BreadcrumbData } from 'use-react-router-breadcrumbs'
import { ClaimLisTabHash } from '@/pages/Claims/Claims.config'
import { FlagUK, FlagVN } from '@/assets'
import { Header, TSideBarItem } from '@sas/components-fe'
import { Lang, RootPaths } from '@/constant'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { RiArrowRightSLine } from 'react-icons/ri'
import { TRoute } from '@/types/common'
import { TranslationKey } from '@/types/i18n'
import { getMediaLink, keycloakClient } from '@/utils'
import {
  useAuth,
  useBreadcrumbs,
  useDocumentTitle,
  useExcelExportSocket,
  useRoutes,
} from '@/hooks'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const getRootPath = (path: string) =>
  `/${path.slice(1).split('/')?.[0]}` || path

enum EMenuAction {
  LOGOUT = 'Logout',
}

const MainLayout = () => {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation()
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs()
  const location = useLocation()
  const [current, setCurrent] = useState(getRootPath(location.pathname))
  const { authorizedRoutes } = useRoutes()
  const [, setDocumentTitle] = useDocumentTitle()

  const [visibleExportCompleteModal, setVisibleExportCompleteModal] =
    useState(false)
  const [visibileAlertMessage, setVisibleAlertMessage] = useState(false)

  const { userPermission, userProfile, currentCountry, setCurrentCountry } =
    useAuth()

  const portalLogo = useMemo(() => {
    const userLogoUrl =
      userPermission?.metadata?.[currentCountry]?.portal_partner_whitelabel_id
        ?.logo

    return userLogoUrl
      ? getMediaLink(userLogoUrl, 'PARTNER_URL_LOGO')
      : undefined
  }, [userPermission])

  const menuItems = [
    {
      label: t('COMMON/LANG_VI'),
      icon: <FlagVN />,
      key: Lang.VI,
      title: t('COMMON/CHANGE_LANG'),
      style: language === Lang.VI ? { fontWeight: 'bold' } : undefined,
    },
    {
      label: t('COMMON/LANG_EN'),
      icon: <FlagUK />,
      key: Lang.EN,
      title: t('COMMON/CHANGE_LANG'),
      style: language === Lang.EN ? { fontWeight: 'bold' } : undefined,
    },
    {
      label: t('MENU/LOGOUT'),
      key: EMenuAction.LOGOUT,
    },
  ]

  const sideBarItems = useMemo<TSideBarItem[] | undefined>(
    () =>
      authorizedRoutes
        .map(route => {
          const isShowSubMenu = route.children?.some(
            ({ showInMenu }) => showInMenu
          )

          const newChildren = isShowSubMenu
            ? route.children
                ?.map(child =>
                  child.showInMenu
                    ? {
                        path: child.path,
                        icon: child.menuIcon,
                        label: t(child.menuLabel as TranslationKey),
                      }
                    : null
                )
                .filter(Boolean)
            : []

          return route.showInMenu
            ? {
                path: route.path,
                icon: route.menuIcon,
                label: t(route.menuLabel as TranslationKey),
                children: newChildren,
                subMenuTitle: route.subMenuTitle,
              }
            : null
        })
        .filter(Boolean) as TSideBarItem[],
    [authorizedRoutes]
  )

  useExcelExportSocket({
    onSuccess: () => {
      setVisibleExportCompleteModal(true)
    },
  })
  useEffect(() => {
    const indexBreadCrumb = breadcrumbs.length > 1 ? 1 : 0
    const mainBreadcrumb = t(
      (breadcrumbs[indexBreadCrumb]?.match.route as TRoute)
        ?.menuLabel as TranslationKey
    )

    setDocumentTitle(mainBreadcrumb)
    setCurrent(getRootPath(location.pathname))
  }, [location.pathname])

  const handleSideBarClick = (key: string) => {
    navigate(key)
  }

  const handleMenuClick = (event: { key: string }) => {
    if (event.key === EMenuAction.LOGOUT) {
      keycloakClient.logout().then(() => {
        navigate(RootPaths.SIGN_IN)
      })
    }
    if (event.key === Lang.EN) {
      changeLanguage(Lang.EN)
    }
    if (event.key === Lang.VI) {
      changeLanguage(Lang.VI)
    }
  }

  const breadcrumbItems = useMemo(
    (): BreadcrumbProps['items'] =>
      breadcrumbs.map(({ match, key }: BreadcrumbData) => {
        const routeBreadcrumb = match.route?.breadcrumb as TranslationKey
        return {
          key,
          title: <Link to={key}>{t(routeBreadcrumb)}</Link>,
        }
      }),
    [language, location.pathname]
  )

  return (
    <>
      <Layout style={{ minHeight: '100%' }}>
        <SideBar
          items={sideBarItems}
          path={current}
          rootPath={RootPaths.DASHBOARD}
          onPathChange={handleSideBarClick}
          theme="light"
          logoSrc={portalLogo}
        />
        <Layout>
          <Header
            leftContent={
              <Breadcrumb
                className={classes.breadcrumb}
                separator={<RiArrowRightSLine />}
                items={breadcrumbItems}
              />
            }
            rightContent={
              <SelectCountry
                value={currentCountry}
                onChange={value => {
                  setCurrentCountry(value)
                }}
              />
            }
            username={`${userProfile?.firstName || ''} ${
              userProfile?.lastName || ''
            }`}
            menuItems={menuItems}
            onMenuClick={handleMenuClick}
          />
          <Layout.Content style={{ padding: '1.5rem' }}>
            <Outlet />
          </Layout.Content>
        </Layout>
      </Layout>

      <ExportCompleteModal
        visible={visibleExportCompleteModal}
        onOk={() => {
          setVisibleExportCompleteModal(false)
          navigate(RootPaths.CLAIMS + ClaimLisTabHash.ListExportHistory)
        }}
        onCancel={() => {
          setVisibleExportCompleteModal(false)
          setVisibleAlertMessage(true)
        }}
      />

      {visibileAlertMessage && (
        <AppAlert
          type="info"
          message={
            <>
              {t('EXPORT_COMPLETE_MODAL/HURA_DATA_EXPORT_IS_COMPLETE')}{' '}
              {t('EXPORT_COMPLETE_MODAL/THE_DATA_FILE_IS_READY_MESSAGE')}{' '}
              <Link
                to={`${RootPaths.CLAIMS}${ClaimLisTabHash.ListExportHistory}`}
              >
                {t('EXPORT_COMPLETE_MODAL/DO_IT_NOW')}!
              </Link>
            </>
          }
          closable
          afterClose={() => {
            setVisibleAlertMessage(false)
          }}
        />
      )}
    </>
  )
}

export default MainLayout
