import { ExportEvent } from '@/constant'
import { useEffect } from 'react'
import { useWebsocket } from '@/hooks/useWebsocket'

type TUseExcelExportSocket = {
  onSuccess: () => void
}
export const useExcelExportSocket = ({ onSuccess }: TUseExcelExportSocket) => {
  const { exportEvent } = useWebsocket()
  useEffect(() => {
    if (
      exportEvent &&
      [
        ExportEvent.CLAIM_PARTNER_EXPORT_SUCCESS,
        ExportEvent.EXPORT_PARTNER_CLAIM_LIST_GENERAL_SUCCESS,
      ].includes(exportEvent.eventName)
    ) {
      onSuccess()
    }
  }, [exportEvent])
}
