import classes from './ClaimHistory.module.less'
import { Button, Tooltip } from '@sas/components-fe'
import { ClaimsPaths, EClaimStatus } from '@/constant'
import { ColumnsType } from 'antd/lib/table'
import { Link, generatePath } from 'react-router-dom'
import { RiEyeLine } from 'react-icons/ri'
import { StatusItem } from '@/components'
import { TClaimHistoryResponse } from '@/types/api'
import { TCountryName } from '@/types/models'
import { formatCurrency } from '@/utils'
import { t } from 'i18next'

export const getClaimHistoryColumns = (country: TCountryName) =>
  [
    {
      title: t('CLAIMS/CLAIM_ID'),
      dataIndex: 'claimNo',
      align: 'center',
    },
    {
      title: t('CLAIMS/MEMBER_NAME'),
      dataIndex: 'memberName',
    },
    {
      title: t('CLAIMS/CARD_NUMBER'),
      dataIndex: 'memberCard',
      align: 'center',
    },
    {
      title: t('CLAIMS/STATUS'),
      dataIndex: 'claimStatus',
      render: (value: EClaimStatus) => (
        <StatusItem value={value} label={value} />
      ),
    },
    {
      title: t('CLAIMS/INCURRED_AMOUNT'),
      dataIndex: 'incurredAmount',
      align: 'right',
      render: value => formatCurrency(+value, country),
    },
    {
      title: t('CLAIMS/COVERED_AMOUNT'),
      dataIndex: 'payableAmount',
      align: 'right',
      render: value => formatCurrency(+value, country),
    },
    {
      title: t('CLAIMS/RECEIVED_DATE'),
      align: 'center',
      dataIndex: 'receivedDate',
    },
    {
      title: t('CLAIMS/CLAIM_TYPE'),
      dataIndex: 'claimType',
    },
    {
      title: t('COMMON/ACTION'),
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 140,
      render: (_: string, record: TClaimHistoryResponse) => (
        <Tooltip title={t('COMMON/VIEW_DETAILS')} destroyTooltipOnHide>
          <Link
            to={generatePath(ClaimsPaths.CLAIM_DETAIL_OVERVIEW, {
              claimId: `${record.claimNo}`,
            })}
          >
            <Button
              type="link"
              icon={<RiEyeLine className={classes['action-btn-icon']} />}
            ></Button>
          </Link>
        </Tooltip>
      ),
    },
  ] satisfies ColumnsType<TClaimHistoryResponse>
