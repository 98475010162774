import Company from '@/pages/Company'
import { CompanyPaths, RootPaths } from '@/constant'
import { RiBuildingFill } from 'react-icons/ri'

import type { TRoute } from '@/types/common/route'

export const CompanyRoutes: TRoute[] = [
  {
    path: RootPaths.COMPANY,
    component: Company,
    page: 'accessible',
    breadcrumb: 'BREADCRUMB/COMPANY',
    showInMenu: true,
    menuIcon: <RiBuildingFill fontSize={24} />,
    menuLabel: 'BREADCRUMB/COMPANY',
  },
  {
    path: CompanyPaths.COMPANY_DETAIL,
    component: Company,
    breadcrumb: null,
    page: 'accessible',
  },
  // {
  //   path: CompanyPaths.COMPANY_EDIT_PROFILE,
  //   component: EditCompanyProfile,
  //   page: 'accessible',
  // },
]
