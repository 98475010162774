import axios, { AxiosInstance } from 'axios'
import queryString from 'query-string'
import { appConfig } from '@/config'
import { getStorage, keycloakClient } from '@/utils'

export const InstanceAxios: AxiosInstance = axios.create({
  baseURL: appConfig.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

InstanceAxios.interceptors.request.use(config => {
  if (config.headers) {
    config.headers['realm-name'] = appConfig.REALM_NAME
    config.headers['portal-name'] = appConfig.PORTAL_NAME
    config.headers['country'] =
      getStorage('country')?.replace(/['"]+/g, '') || 'vietnam'

    if (keycloakClient?.token) {
      config.headers['Authorization'] = keycloakClient.token
    }

    // Trim payload
    config.data = trimStringFieldInPayload(config.data)
    // Trim queries
    if (config.url) {
      const { query, url } = queryString.parseUrl(config.url)
      config.url = queryString.stringifyUrl({
        query: trimStringFieldInPayload(query),
        url,
      })
    }
  }

  return config
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const trimStringFieldInPayload = (payload: any) => {
  for (const field in payload) {
    if (typeof payload[field] === 'string') {
      payload[field] = payload[field].trim()
    } else if (typeof payload[field] === 'object') {
      trimStringFieldInPayload(payload[field])
    } else if (Array.isArray(payload[field])) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload[field] = payload[field].map((item: any) =>
        trimStringFieldInPayload(item)
      )
    } else {
      payload[field] = payload[field]
    }
  }
  return payload
}
