import { ClaimsPaths } from '@/constant'
import { TCountryName } from '@/types/models'
import { TTabProps } from '@sas/components-fe'
import { t } from 'i18next'

export const getTabsConfig = (
  params: Record<string, string | undefined>
): TTabProps[] => {
  return [
    {
      to: ClaimsPaths.CLAIM_DETAIL_OVERVIEW,
      title: t('CLAIMS/OVERVIEW'),
      key: ClaimsPaths.CLAIM_DETAIL_OVERVIEW,
      params,
    },
    {
      to: ClaimsPaths.CLAIM_DETAIL_CLAIM_BREAKDOWN,
      title: t('CLAIMS/CLAIM_BREAKDOWN'),
      key: ClaimsPaths.CLAIM_DETAIL_CLAIM_BREAKDOWN,
      params,
    },
    {
      to: ClaimsPaths.CLAIM_DETAIL_GENERAL_INFORMATION,
      title: t('CLAIMS/GENERAL_INFO'),
      key: ClaimsPaths.CLAIM_DETAIL_GENERAL_INFORMATION,
      params,
    },
    {
      to: ClaimsPaths.CLAIM_DETAIL_ATTACHMENT,
      title: t('CLAIMS/ATTACHMENT'),
      key: ClaimsPaths.CLAIM_DETAIL_ATTACHMENT,
      params,
    },
    {
      to: ClaimsPaths.CLAIM_DETAIL_CLAIM_HISTORY,
      title: t('CLAIMS/HISTORY'),
      key: ClaimsPaths.CLAIM_DETAIL_CLAIM_HISTORY,
      params,
    },
    {
      to: ClaimsPaths.CLAIM_DETAIL_EXPORT_HISTORY,
      title: t('CLAIMS/EXPORT_HISTORY'),
      key: ClaimsPaths.CLAIM_DETAIL_EXPORT_HISTORY,
      params,
    },
  ]
}

export const PDF_CURRENCY: Record<TCountryName, string> = {
  vietnam: 'VND',
  cambodia: 'USD',
}

export const EXPORT_FILE_NAME = 'payment-breakdown'
