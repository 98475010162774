import { Fragment } from 'react'
import { Skeleton } from 'antd'
import { TClaimDetailResponse, TMemberPolicyLimitResponse } from '@/types/api'
import { TOverviewConfig } from './Overview.types'
import { formatNumber } from '@sas/components-fe'
import { getRangeNumber } from '@/utils'
import { t } from 'i18next'

const getMemberPolicyLimit = (
  memberPolicyLimit?: TMemberPolicyLimitResponse | null,
  isLoading?: boolean
): TOverviewConfig[] => {
  if (isLoading) {
    return getRangeNumber(4).map(item => ({
      content: (
        <Fragment key={item}>
          <Skeleton.Input
            active
            size="small"
            block
            style={{ marginBottom: 8 }}
          />
          <Skeleton.Input active size="small" block />
        </Fragment>
      ),
    }))
  }

  return memberPolicyLimit
    ? memberPolicyLimit.reduce(
        (prevValue, value: TMemberPolicyLimitResponse[number]) => [
          ...prevValue,
          {
            title: value.name,
            content: formatNumber(value.value, { allowZero: true }),
          },
        ],
        [] as TOverviewConfig[]
      )
    : []
}

export const getOverviewConfig = (
  claimDetail?: TClaimDetailResponse | null,
  memberPolicyLimit?: TMemberPolicyLimitResponse | null,
  claimLoading?: boolean,
  policyLoading?: boolean
): TOverviewConfig[] => {
  const { memberInformation, companyInformation, policyInformation } =
    claimDetail || {}
  const {
    memberName,
    dateOfBirth,
    memberNationalId,
    staffId,
    type,
    cardNumber,
    firstEffectiveDate,
  } = memberInformation || {}
  const { companyName, companyAddress } = companyInformation || {}
  const { policyEffectiveDate, policyExpiryDate, policyNumber } =
    policyInformation || {}
  const { externalPlanName } = policyInformation || {}

  return [
    {
      key: 'member-info',
      title: t('CLAIMS/MEMBER_INFO'),
      children: [
        {
          title: t('CLAIMS/MEMBER_NAME'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            memberName
          ),
        },
        {
          title: t('CLAIMS/DOB'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            dateOfBirth
          ),
        },
        {
          title: t('CLAIMS/MEMBER_NATIONAL_ID'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            memberNationalId
          ),
        },
        {
          title: t('CLAIMS/STAFF_EMPLOYEE_ID'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            staffId
          ),
        },
        {
          title: t('CLAIMS/TYPE'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            type
          ),
        },
        {
          title: t('CLAIMS/CARD_NUMBER'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            cardNumber
          ),
        },
        {
          title: t('CLAIMS/FIRST_EFFECTIVE'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            firstEffectiveDate
          ),
        },
        {
          title: t('CLAIMS/DATE'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            '-'
          ),
        },
        {
          title: t('CLAIMS/NUMBER'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            '-'
          ),
          style: {
            gridColumn: '4/6',
          },
        },
      ],
    },
    {
      key: 'company-client-info',
      title: t('CLAIMS/COMPANY_CLIENT_INFO'),
      children: [
        {
          title: t('CLAIMS/COMPANY_NAME'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            companyName
          ),
          style: {
            gridColumn: '1/3',
          },
        },
        {
          title: t('CLAIMS/COMPANY_ADDRESS'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            companyAddress
          ),
          style: {
            gridColumn: '3/6',
          },
        },
      ],
    },
    {
      key: '323',
      title: t('CLAIMS/POLICY_INFO'),
      children: [
        {
          title: t('CLAIMS/POLICY_EFFECTIVE_DATE'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            policyEffectiveDate
          ),
        },
        {
          title: t('CLAIMS/POLICY_EXP_DATE'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            policyExpiryDate
          ),
        },
        {
          title: t('CLAIMS/POLICY_NUMBER'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            policyNumber
          ),
          style: {
            gridColumn: '3/6',
          },
        },
        {
          title: t('CLAIMS/PLAN'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            externalPlanName
          ),
        },
        ...getMemberPolicyLimit(memberPolicyLimit, policyLoading),
      ],
    },
    {
      key: '2323',
      title: t('CLAIMS/SUBMISSION_REMARKS'),
      children: [
        {
          title: t('CLAIMS/SUBMISSION_REMARKS'),
          content: claimLoading ? (
            <Skeleton.Input active size="small" block />
          ) : (
            '-'
          ),
          style: {
            gridColumn: '1/6',
          },
        },
      ],
    },
  ]
}
