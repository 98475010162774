import classes from './Overview.module.less'
import { Collapse, CollapseProps } from 'antd'
import { RootPaths } from '@/constant'
import { getOverviewConfig } from './Overview.config'
import { openNotification } from '@sas/components-fe'
import { uniqueId } from 'lodash'
import { useGetClaimDetail, useGetMemberPolicyLimit } from '@/api'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Overview = () => {
  const { t } = useTranslation()
  const { claimId } = useParams()
  const navigate = useNavigate()
  const {
    data: claimDetail,
    isLoading: claimLoading,
    isFetching: claimFetching,
    isSuccess,
  } = useGetClaimDetail(claimId || '')
  const [memberCard, memberCardPad] =
    claimDetail?.memberInformation?.cardNumber?.split('/') || []
  const {
    data: memberPolicyLimit,
    isLoading: policyLoading,
    isFetching: policyFetching,
  } = useGetMemberPolicyLimit(memberCard || '', memberCardPad || '')

  const isLoading =
    claimLoading || policyLoading || claimFetching || policyFetching

  const overviewConfig = useMemo(
    () =>
      getOverviewConfig(
        claimDetail,
        memberPolicyLimit,
        isLoading,
        policyLoading
      ),
    [t, claimDetail, memberPolicyLimit, isLoading, policyLoading]
  )

  if (isSuccess && !claimDetail) {
    openNotification({
      notificationType: 'Error',
      message: t('CLAIMS/CLAIM_ID_NOT_EXISTS', { claimId }),
    })
    navigate(RootPaths.CLAIMS)
    return null
  }

  return (
    <div className={classes.ctn}>
      {overviewConfig.map(({ title, children }) => {
        const collapseItems: CollapseProps['items'] = [
          {
            key: title,
            label: title,
            children: (
              <div className={classes.grid}>
                {children?.map(({ title: childTitle, content, style }) => {
                  return (
                    <div
                      key={uniqueId()}
                      className={classes['grid-item']}
                      style={style}
                    >
                      <div className={classes['grid-item-title']}>
                        {childTitle}
                      </div>
                      <div className={classes['grid-item-content']}>
                        {content || '-'}
                      </div>
                    </div>
                  )
                })}
              </div>
            ),
          },
        ]

        return (
          <Collapse
            key={title}
            items={collapseItems}
            defaultActiveKey={[`${title}`]}
            className={classes.collapse}
          />
        )
      })}
    </div>
  )
}

export default Overview
