import { CURRENCY } from './constants'
import { TCountryName } from './../types/models/user.d'

export const kebabCaseToPascalCase = (text: string) => {
  return text.replace(/(^\w|-\w)/g, clearAndUpper)
}

export const clearAndUpper = (text: string) => {
  return text.replace(/-/, ' ').toUpperCase()
}

export const formatCurrency = (
  value: number,
  key: TCountryName = 'vietnam'
) => {
  const currency = CURRENCY[key]
  const formattedNumber = new Intl.NumberFormat().format(value)

  return `${formattedNumber} ${currency.symbol}`
}
