import { TTabProps } from '@sas/components-fe'
import { t } from 'i18next'

export enum ClaimLisTabHash {
  ClaimList = '#list',
  ListExportHistory = '#export-history',
}

export const getTabConfigs = (): TTabProps[] => [
  {
    to: ClaimLisTabHash.ClaimList,
    title: t('CLAIMS/CLAIM_LISTING'),
    key: ClaimLisTabHash.ClaimList,
  },
  {
    to: ClaimLisTabHash.ListExportHistory,
    title: t('CLAIMS/EXPORT_HISTORY'),
    key: ClaimLisTabHash.ListExportHistory,
  },
]
