import classes from './ClaimBreakdown.module.less'
import { Table as AntdTable } from 'antd'
import { RootPaths } from '@/constant'
import { TBreakDownResponse } from '@/types/api'
import { Table, formatNumber, openNotification } from '@sas/components-fe'
import { formatCurrency } from '@/utils'
import { getClaimBreakdownColumns } from './ClaimBreakdown.config'
import { uniqueId } from 'lodash'
import { useAuth } from '@/hooks'
import { useGetBreakdown } from '@/api'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

const getSumBreakdown = (
  data: Array<TBreakDownResponse>,
  fieldName: string
) => {
  if (!data) return 0

  return data.reduce((accumulator: number, current: TBreakDownResponse) => {
    return accumulator + parseFloat(current[fieldName] as string)
  }, 0)
}

const ClaimBreakdown = () => {
  const { currentCountry } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const claimBreakdownColumns = getClaimBreakdownColumns(currentCountry)
  const { claimId } = useParams()
  const {
    data: breakdownData,
    isLoading,
    isSuccess,
  } = useGetBreakdown(claimId || '')
  const [page, setPage] = useState(1)

  const breakdownDataWithId = useMemo(() => {
    return breakdownData
      ? breakdownData.map(item => ({ ...item, id: uniqueId() }))
      : []
  }, [breakdownData])

  const totalMedicalExpense = getSumBreakdown(
    breakdownData as Array<TBreakDownResponse>,
    'medicalExpense'
  )
  const totalPayableByClient = getSumBreakdown(
    breakdownData as Array<TBreakDownResponse>,
    'payableByClient'
  )
  const totalCoveredAmount = getSumBreakdown(
    breakdownData as Array<TBreakDownResponse>,
    'coveredAmount'
  )

  if (isSuccess && !breakdownData) {
    openNotification({
      notificationType: 'Error',
      message: t('CLAIMS/CLAIM_ID_NOT_EXISTS', { claimId }),
    })
    navigate(RootPaths.CLAIMS)
    return null
  }

  const handlePageChange = (page?: number) => {
    setPage(page ?? 1)
  }

  return (
    <div className={classes.ctn}>
      <Table
        loading={isLoading}
        columns={claimBreakdownColumns}
        dataSource={breakdownDataWithId as Array<TBreakDownResponse>}
        page={page}
        setPage={handlePageChange}
        pagination={false}
        summary={() => {
          return (
            <AntdTable.Summary fixed="bottom">
              <AntdTable.Summary.Row>
                <AntdTable.Summary.Cell index={0} colSpan={2}>
                  {t('CLAIMS/TOTAL_PAYMENT')}
                </AntdTable.Summary.Cell>
                <AntdTable.Summary.Cell
                  index={2}
                  align="right"
                  className={classes['sum-cell']}
                >
                  {formatCurrency(totalMedicalExpense, currentCountry)}
                </AntdTable.Summary.Cell>
                <AntdTable.Summary.Cell index={3}>{''}</AntdTable.Summary.Cell>
                <AntdTable.Summary.Cell
                  index={4}
                  align="right"
                  className={classes['sum-cell']}
                >
                  {formatCurrency(totalPayableByClient, currentCountry)}
                </AntdTable.Summary.Cell>
                <AntdTable.Summary.Cell
                  index={5}
                  align="right"
                  className={classes['sum-cell']}
                >
                  {formatCurrency(totalCoveredAmount, currentCountry)}
                </AntdTable.Summary.Cell>
              </AntdTable.Summary.Row>
            </AntdTable.Summary>
          )
        }}
        showColumnNumber={false}
        total={breakdownDataWithId?.length}
        rowKey="id"
        showTotal={(total: number, range: number[]) =>
          t('COMMON/DISPLAYING_OF_ITEMS', {
            start: range[0],
            end: range[1],
            total: formatNumber(total, { allowZero: true }),
          })
        }
      />
    </div>
  )
}

export default ClaimBreakdown
