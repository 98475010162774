import classes from './ClaimsDetail.module.less'
import { Button, Tabs, Typography, openNotification } from '@sas/components-fe'
import { ExportEvent } from '@/constant'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router'
import { StatusItem } from '@/components'
import { getTabsConfig } from './ClaimDetails.config'
import { useCreateExportClaimDetail, useGetClaimDetail } from '@/api'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useWebsocket } from '@/hooks/useWebsocket'

type TClaimsDetailParams = {
  claimId: string
}

const ClaimsDetail = () => {
  const location = useLocation()
  const { exportEvent, refetchData } = useWebsocket()
  const navigate = useNavigate()

  const { claimId } = useParams<TClaimsDetailParams>()
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const {
    data: dataClaimDetail,
    isLoading: claimLoading,
    isFetching: claimFetching,
  } = useGetClaimDetail(claimId || '')

  const claimStatus = dataClaimDetail?.generalInformation?.claimStatus
  const {
    isLoading: createExportClaimDetailLoading,
    isSuccess: createExportClaimDetailSuccess,
    mutate: createExportClaimDetail,
  } = useCreateExportClaimDetail()

  const isDisableExportButton = useMemo(() => {
    return claimLoading || claimFetching || createExportClaimDetailLoading
  }, [claimLoading, claimFetching, createExportClaimDetailLoading])

  const handleDownloadPDFClick = () => {
    if (claimId) {
      createExportClaimDetail({ claimNo: claimId, language })
    }
  }

  useEffect(() => {
    if (createExportClaimDetailSuccess) {
      openNotification({
        notificationType: 'Success',
        message: t('CLAIMS/EXPORT_SENT_SUCCESS'),
      })
      refetchData()
    }
  }, [createExportClaimDetailSuccess])

  useEffect(() => {
    if (
      exportEvent &&
      exportEvent.eventName === ExportEvent.CLAIM_PARTNER_EXPORT_DETAIL_SUCCESS
    ) {
      openNotification({
        notificationType: 'Success',
        message: t('CLAIMS/EXPORT_COMPLETED'),
      })
    }
  }, [exportEvent])

  return (
    <div className={classes.ctn}>
      <div className={classes['title-ctn']}>
        <div className={classes['wrapper-title-ctn']}>
          <Typography
            className={classes['wrapper-title']}
            color="text"
            colorVariant={100}
            fontWeight={700}
            fontSize="1.25rem"
          >
            {t('CLAIMS/CLAIM_ID')} {claimId}
          </Typography>
          <StatusItem type="bold" label={claimStatus} value={claimStatus} />
        </div>
        <Button
          type="primary"
          onClick={handleDownloadPDFClick}
          disabled={isDisableExportButton}
          loading={isDisableExportButton}
        >
          {t('CLAIMS/DOWNLOAD_PDF')}
        </Button>
      </div>
      <Tabs
        activeKey={location.pathname}
        items={getTabsConfig({ claimId })}
        onChangePath={navigate}
      />
      <Outlet />
    </div>
  )
}

export default ClaimsDetail
