import { ColumnsType } from 'antd/lib/table'
import { TBreakDownResponse } from '@/types/api'
import { TCountryName } from '@/types/models'
import { formatCurrency } from '@/utils'
import { t } from 'i18next'

export const getClaimBreakdownColumns = (country: TCountryName) =>
  [
    {
      title: t('CLAIMS/TREATMENT_DATE'),
      dataIndex: 'treatmentDate',
    },
    {
      title: t('CLAIMS/NATURAL_OF_TREATMENT'),
      dataIndex: 'treatmentDescription',
      render: (_: string, record: TBreakDownResponse) => {
        const { treatmentDescription } = record as TBreakDownResponse
        return <span>{treatmentDescription}</span>
      },
    },
    {
      title: t('CLAIMS/MEDICAL_EXPENSE'),
      dataIndex: 'medicalExpense',
      render: value => formatCurrency(+value, country),
      align: 'right',
    },
    {
      title: t('CLAIMS/COVERAGE_RATIO'),
      dataIndex: 'coverageRatio',
      align: 'right',
      render: (value: number) => `${value}%`,
    },
    {
      title: t('CLAIMS/PAYABLE_BY_THE_CLIENT'),
      dataIndex: 'payableByClient',
      render: value => formatCurrency(+value, country),
      align: 'right',
    },
    {
      title: t('CLAIMS/COVERED_AMOUNT'),
      dataIndex: 'coveredAmount',
      render: value => formatCurrency(+value, country),
      align: 'right',
    },
    {
      title: t('CLAIMS/REMARK'),
      dataIndex: 'remark',
      render: value => value || '-',
    },
  ] satisfies ColumnsType<TBreakDownResponse>
