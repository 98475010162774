import classes from './SelectCountry.module.less'
import { COUNTRIES } from '@/utils'
import { Dropdown, Space } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { RiArrowDownSLine } from 'react-icons/ri'
import { TCountryName } from '@/types/models'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

type TSelectCountryProps = {
  value?: TCountryName
  onChange?: (value: TCountryName) => void
}

const SelectCountry = ({ value, onChange }: TSelectCountryProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [visible, setVisible] = useState<boolean>(false)

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible)
  }

  const handleMenuClick = (event: { key: string }) => {
    onChange?.(event.key as TCountryName)
    setVisible(false)
  }

  const menuItems = useMemo<ItemType[]>(
    () =>
      COUNTRIES.map(({ flag: Flag, name, value }) => ({
        key: value,
        label: t(name),
        icon: <Flag />,
      })),
    [language]
  )

  const { flag: CountryFlag, name: countryName } = useMemo(
    () =>
      COUNTRIES.find(({ value: countryValue }) => countryValue === value) ??
      COUNTRIES[0],
    [value]
  )

  return (
    <Dropdown
      menu={{
        items: menuItems,
        selectable: true,
        onClick: handleMenuClick,
        defaultSelectedKeys: value ? [value] : [],
      }}
      onOpenChange={handleVisibleChange}
      open={visible}
      trigger={['click']}
    >
      <Space className={classes['selected-item']}>
        <CountryFlag />
        <span>{t(countryName)}</span>
        <RiArrowDownSLine className={classes.icon} />
      </Space>
    </Dropdown>
  )
}

export default SelectCountry
