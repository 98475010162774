import classes from './FileItem.module.less'
import clsx from 'clsx'
import { AuthImage } from '@/components/AuthImage'
import { AxiosProgressEvent } from 'axios'
import { Button } from '@sas/components-fe'
import { DeleteIcon } from '../DeleteIcon'
import { DoneICon } from '../DoneIcon'
import { Progress } from 'antd'
import { TFileUploadItem, TUploadItem } from '@/types'
import { createUploadDocument } from '@/api'
import { mapFileTypeToIcon } from '@/utils'
import { useEffect, useMemo, useRef, useState } from 'react'

type TFileItemProps = {
  data: TFileUploadItem
  onUploading: (id: string) => void
  onRemove?: (id: string) => void
  onError?: (id: string) => void
  onUploadItemSuccess: (id: string, uploadItem: TUploadItem) => void
}

const FileItem = ({
  onUploading = () => {},
  onRemove = () => {},
  onError = () => {},
  onUploadItemSuccess,
  data,
}: TFileItemProps) => {
  const [state, setState] = useState(data)
  const [isUploading, setIsUploading] = useState(false)
  const [isError, setIsError] = useState(false)
  const fileNameWithoutExtension = useMemo(() => {
    return state.file?.name?.split('.').slice(0, -1).join('.')
  }, [state.file])
  const ref = useRef(false)

  const fileExtension = useMemo(() => {
    return '.' + state.file?.name?.split('.').pop()
  }, [state.file])

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    const percent = Math.round(
      (progressEvent.loaded * 100) / (progressEvent.total || 1)
    )
    setState(prevState => ({ ...prevState, progress: percent }))
  }

  const onUploadSuccess = (result: TUploadItem) => {
    setIsUploading(false)
    setState(prevState => ({ ...prevState, ...result, success: true }))
    onUploadItemSuccess(data.id, result)
  }

  const onUpload = () => {
    if (
      !data?.file ||
      data.success ||
      data.error ||
      ref.current ||
      data.uploading
    ) {
      return
    }
    setIsUploading(true)
    onUploading(data.id)
    const formData = new FormData()
    formData.set('document', data.file)
    createUploadDocument(formData, onUploadProgress, onUploadSuccess).catch(
      () => {
        setIsError(true)
        onError(data.id)
      }
    )
  }

  useEffect(() => {
    onUpload()
  }, [])

  return (
    <div className={classes['file-item']}>
      <div className={classes['file-metadata']}>
        <AuthImage
          className={classes['file-icon']}
          src={mapFileTypeToIcon(state.file.type || '')}
        />
        <span
          className={clsx([classes['file-name'], isError && classes['error']])}
        >
          {fileNameWithoutExtension}
        </span>
        <span className={clsx([isError && classes['error']])}>
          {fileExtension}
        </span>
      </div>
      <Progress
        percent={state.progress}
        size="small"
        showInfo={false}
        strokeWidth={2}
        className={classes['progress-bar']}
      />
      <div className={classes['action-icons']}>
        {isUploading && !isError && <span>Uploading...</span>}
        {((!isUploading && !isError) || data.success) && <DoneICon />}
        {(!isUploading || isError || data.success || data.error) && (
          <Button
            type="text"
            shape="circle"
            size="small"
            onClick={() => onRemove(data.id)}
            icon={<DeleteIcon />}
          />
        )}
      </div>
    </div>
  )
}

export default FileItem
