import qs from 'query-string'
import { HttpService } from './http-service'
import { REST_API_EXPORT } from '@/api'
import {
  TCheckExportRequestPayload,
  TCheckExportRequestResponse,
  TCreateExportClaimDetailPayload,
  TExportPayload,
  TExportResponse,
  TPaginationResponse,
} from '@/types'
import { generatePath } from 'react-router-dom'

export const getExportHistory = async (
  payload: TExportPayload
): Promise<TPaginationResponse<TExportResponse[]>> => {
  const route = `${REST_API_EXPORT.GET_EXPORT_HISTORY.uri}?${qs.stringify(
    payload
  )}`

  return HttpService.get<TPaginationResponse<TExportResponse[]>>(route)
}

export const createExportClaimDetail = async (
  payload: TCreateExportClaimDetailPayload
): Promise<void> => {
  return HttpService.post<TCreateExportClaimDetailPayload, void>(
    REST_API_EXPORT.CREATE_EXPORT_CLAIM_DETAIL.uri,
    payload
  )
}

export const cancelExportClaims = async (id: string): Promise<void> => {
  const route = generatePath(REST_API_EXPORT.CANCEL_EXPORT_LIST_CLAIM.uri, {
    id,
  })

  return HttpService.patch(route)
}

export const checkExportRequest = async (
  payload: TCheckExportRequestPayload
): Promise<TCheckExportRequestResponse> => {
  const route = `${REST_API_EXPORT.CHECK_EXPORT_REQUEST.uri}?${qs.stringify(
    payload
  )}`

  return HttpService.get(route)
}
