import classes from './DeleteIcon.module.less'
import { RiCloseFill } from 'react-icons/ri'

const DeleteIcon = () => {
  return (
    <div className={classes['icon-container']}>
      <RiCloseFill />
    </div>
  )
}

export default DeleteIcon
