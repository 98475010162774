import classes from './ClaimHistory.module.less'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import {
  COMMON_DATE_FORMAT,
  EDateFormat,
  RangePicker,
  Table,
  formatNumber,
  openNotification,
} from '@sas/components-fe'
import { RangePickerProps } from 'antd/es/date-picker'
import { RootPaths } from '@/constant'
import { appConfig } from '@/config'
import { getClaimHistoryColumns } from './ClaimHistory.config'
import { useAuth } from '@/hooks'
import { useEffect, useState } from 'react'
import { useGetClaimDetail, useGetClaimHistory } from '@/api'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

const ClaimHistory = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { currentCountry } = useAuth()
  const [page, setPage] = useState(1)
  const [dateRange, setDateRange] = useState<RangePickerProps['value']>()
  const { claimId } = useParams()

  const {
    data: claimDetail,
    isSuccess,
    isFetching,
  } = useGetClaimDetail(claimId || '')
  const claimHistoryColumns = getClaimHistoryColumns(currentCountry)

  const [memberNumber, suffix] = (claimDetail &&
    claimDetail?.memberInformation?.cardNumber?.split('/')) || [0, 0]
  const { data: claimHistory, isLoading: claimLoading } = useGetClaimHistory(
    {
      memberNumber: Number(memberNumber),
      suffix: Number(suffix),
    },
    {
      page: `${page}`,
      limit: appConfig.LIMIT_TABLE_SIZE,
      treatmentDateFrom:
        dateRange?.[0]?.format(EDateFormat.COMMON_DATE_FORMAT) || '',
      treatmentDateTo:
        dateRange?.[1]?.format(EDateFormat.COMMON_DATE_FORMAT) || '',
    },
    !isEmpty(dateRange)
  )

  useEffect(() => {
    if (!isSuccess || isFetching) {
      return
    }

    if (!claimDetail) {
      openNotification({
        notificationType: 'Error',
        message: t('CLAIMS/CLAIM_ID_NOT_EXISTS', { claimId }),
      })

      navigate(RootPaths.CLAIMS)

      return
    }

    setDateRange([
      dayjs(
        claimDetail.policyInformation?.policyEffectiveDate,
        COMMON_DATE_FORMAT
      ),
      dayjs(
        claimDetail.policyInformation?.policyExpiryDate,
        COMMON_DATE_FORMAT
      ),
    ])
  }, [isSuccess, isFetching])

  const handlePageChange = (page?: number) => {
    setPage(page ?? 1)
  }

  const handleChangeFilter = (value: RangePickerProps['value']) => {
    setDateRange(value)
  }

  return (
    <div className={classes.ctn}>
      <div className={classes.title}>
        <div className={classes['title-text']}>
          {t('CLAIMS/MOST_RECENT_CLAIMS')}
        </div>
        <div className={classes.status}>
          <RangePicker
            picker="date"
            format={EDateFormat.COMMON_DATE_FORMAT}
            separator="-"
            className={classes['page-filter']}
            onChange={handleChangeFilter}
            allowClear={false}
            value={dateRange}
          />
        </div>
      </div>
      <Table
        columns={claimHistoryColumns}
        dataSource={claimHistory?.data}
        page={page}
        setPage={handlePageChange}
        showColumnNumber={false}
        total={claimHistory?.total || 0}
        loading={claimLoading}
        rowKey="claimNo"
        showTotal={(total: number, range: number[]) =>
          t('COMMON/DISPLAYING_OF_ITEMS', {
            start: range[0],
            end: range[1],
            total: formatNumber(total, { allowZero: true }),
          })
        }
      />
    </div>
  )
}

export default ClaimHistory
