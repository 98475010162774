import Attachment from '@/pages/Claims/ClaimsDetail/Attachment'
import ClaimBreakdown from '@/pages/Claims/ClaimsDetail/ClaimBreakdown'
import ClaimHistory from '@/pages/Claims/ClaimsDetail/ClaimHistory'
import Claims from '@/pages/Claims'
import ClaimsDetail from '@/pages/Claims/ClaimsDetail'
import ExportHistory from '@/pages/Claims/ClaimsDetail/ExportHistory'
import GeneralInformation from '@/pages/Claims/ClaimsDetail/GeneralInformation'
import Overview from '@/pages/Claims/ClaimsDetail/Overview'
import { ClaimsPaths, RootPaths } from '@/constant'
import { RiSearchLine } from 'react-icons/ri'

import type { TRoute } from '@/types/common/route'

export const ClaimsRoutes: TRoute[] = [
  {
    path: RootPaths.CLAIMS,
    component: Claims,
    page: 'claims',
    permission: 'view',
    showInMenu: true,
    breadcrumb: 'BREADCRUMB/SEARCH_CLAIMS',
    menuIcon: <RiSearchLine fontSize={24} />,
    menuLabel: 'BREADCRUMB/SEARCH_CLAIMS',
  },
  {
    path: ClaimsPaths.CLAIM_DETAIL,
    component: ClaimsDetail,
    redirectTo: ClaimsPaths.CLAIM_DETAIL_OVERVIEW,
    page: 'claimDetail',
    breadcrumb: null,
    permission: 'view',
    children: [
      {
        path: ClaimsPaths.CLAIM_DETAIL_OVERVIEW,
        component: Overview,
        page: 'claimDetail',
        permission: 'view',
        breadcrumb: 'BREADCRUMB/OVERVIEW',
      },
      {
        path: ClaimsPaths.CLAIM_DETAIL_CLAIM_BREAKDOWN,
        component: ClaimBreakdown,
        page: 'claimDetail',
        permission: 'view',
        breadcrumb: 'BREADCRUMB/CLAIM_BREAKDOWN',
      },
      {
        path: ClaimsPaths.CLAIM_DETAIL_GENERAL_INFORMATION,
        component: GeneralInformation,
        page: 'claimDetail',
        permission: 'view',
        breadcrumb: 'BREADCRUMB/GENENERAL_INFORMATON',
      },
      {
        path: ClaimsPaths.CLAIM_DETAIL_ATTACHMENT,
        component: Attachment,
        page: 'claimDetail',
        permission: 'view',
        breadcrumb: 'BREADCRUMB/ATTACHMENT',
      },
      {
        path: ClaimsPaths.CLAIM_DETAIL_CLAIM_HISTORY,
        component: ClaimHistory,
        page: 'claimDetail',
        permission: 'view',
        breadcrumb: 'BREADCRUMB/CLAIMS_HISTORY',
      },
      {
        path: ClaimsPaths.CLAIM_DETAIL_EXPORT_HISTORY,
        component: ExportHistory,
        page: 'claimDetail',
        permission: 'view',
        breadcrumb: 'BREADCRUMB/EXPORT_HISTORY',
      },
    ],
  },
]
