import SharedDocuments from '@/pages/SharedDocuments'
import { RootPaths } from '@/constant'

import type { TRoute } from '@/types/common/route'

export const SharedDocumentsRoutes: TRoute[] = [
  {
    path: RootPaths.SHARED_DOCUMENTS,
    component: SharedDocuments,
    // page: 'accessible',
    // permission: 'view',
    breadcrumb: 'MENU/SHARED_DOCUMENTS',
  },
]
