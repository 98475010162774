import * as React from 'react'
import classes from './SideBar.module.less'
import clsx from 'clsx'
import { AuthImage } from '../AuthImage'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { LOGOBRANCH } from '@sas/components-fe'
import { Layout, Menu } from 'antd'
import { RiCheckboxBlankCircleFill } from 'react-icons/ri'
import { formatLabel } from './SideBar.config'
import { useEffect, useMemo, useState } from 'react'

export type TSideBarItem = {
  path: string
  hide?: boolean
  icon?: React.ReactNode
  label?: string
  children?: TSideBarItem[]
  parent?: TSideBarItem
  subMenuTitle?: string
}

export type TSideBarProps = {
  className?: string
  classes?: Partial<typeof classes>
  logoSrc?: string
  items?: TSideBarItem[]
  rootPath?: string
  path?: string
  onPathChange?: (path: string) => void
  subMenuFullHeight?: boolean
  showSubmenuTitle?: boolean
  theme?: string
}

export default function SideBar({
  className,
  classes: overrideClasses = {},
  logoSrc,
  rootPath = '/',
  items = [],
  path = '/',
  onPathChange = () => {},
  subMenuFullHeight,
  showSubmenuTitle = false,
  theme,
}: TSideBarProps) {
  const [currentPath, setCurrentPath] = useState(path)
  const [selectedSubMenu, setSelectedSubMenu] = useState<TSideBarItem | null>(
    null
  )

  const handleChangePath = (p: string) => {
    onPathChange(p)
    setCurrentPath(p)
  }

  const onLogoClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault()
    handleChangePath(rootPath)
  }

  useEffect(() => {
    setCurrentPath(path)
  }, [path])

  const menuItems = useMemo<ItemType[] | undefined>(
    () =>
      items.reduce<ItemType[]>(function reduceMenuItems(
        prev,
        { path, children, hide, label: labelItem, icon: Icon, subMenuTitle }
      ) {
        const label = formatLabel(labelItem as string)
        if (hide) {
          return prev
        }

        const newItem: ItemType = {
          key: path,
          title: labelItem,
          label,
          className: clsx(classes['menu-item'], overrideClasses['menu-item']),
          ...(children &&
            children.length > 0 && {
              popupClassName: clsx(classes.popover, overrideClasses.popover),
            }),
          onClick: ({ key }) => handleChangePath(key),
          icon: (
            <div className={classes['menu-item-icon-ctn']}>
              <div className={classes['menu-item-icon']}>{Icon}</div>
            </div>
          ),
        }

        if (children && children.length > 0) {
          if (subMenuFullHeight) {
            return [
              ...prev,
              {
                ...newItem,
                onClick: () => {},
                onMouseEnter: () =>
                  setSelectedSubMenu({
                    path,
                    children,
                    hide,
                    label,
                    icon: Icon,
                    subMenuTitle,
                  }),
              },
            ]
          }

          return [
            ...prev,
            {
              ...newItem,
              children: children.reduce<ItemType[]>(reduceMenuItems, []),
              onClick: () => {},
            },
          ]
        }

        return [...prev, newItem]
      },
      []),
    [items, subMenuFullHeight]
  )

  const subMenus = useMemo<ItemType[] | undefined>(() => {
    const submenuItems = items
      .find(item => item.path === selectedSubMenu?.path)
      ?.children?.reduce<ItemType[]>(
        function reduceSubMenuItems(
          prev,
          { path, children, hide, label, icon: Icon }
        ) {
          if (hide) {
            return prev
          }

          const newItem: ItemType = {
            key: path,
            label,
            className: clsx(
              classes['menu-full-height-item'],
              overrideClasses['menu-full-height-item']
            ),
            icon: Icon ? Icon : <RiCheckboxBlankCircleFill fontSize={24} />,
            onClick: ({ key }) => handleChangePath(key),
          }

          if (children && children.length > 0) {
            return [
              ...prev,
              {
                ...newItem,
                onClick: () => {},
                children: children?.reduce<ItemType[]>(reduceSubMenuItems, []),
              },
            ]
          }

          return [...prev, newItem]
        },

        []
      )

    return showSubmenuTitle && submenuItems
      ? [
          {
            key: 'menu-full-height-title',
            label: selectedSubMenu?.subMenuTitle || selectedSubMenu?.label,
            className: clsx(
              classes['menu-full-height-title'],
              overrideClasses['menu-full-height-title']
            ),
            icon: null,
            disabled: true,
          },
          ...submenuItems,
        ]
      : submenuItems
  }, [items, selectedSubMenu, subMenuFullHeight])

  const selectedKeys = useMemo(() => {
    const paths = (
      currentPath.startsWith('/') ? currentPath.slice(1) : currentPath
    ).split('/')

    return paths.reduce<string[]>(
      (prev, curr, currIdx) => [...prev, `${prev[currIdx - 1] || ''}/${curr}`],
      []
    )
  }, [currentPath])

  return (
    <div className={classes['side-bar-ctn']}>
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={true}
        width={200}
        className={clsx(
          classes['side-bar'],
          className,
          overrideClasses['side-bar'],
          theme === 'light' && classes['light']
        )}
        theme="light"
      >
        <a
          href={rootPath}
          className={clsx(classes.logo, overrideClasses.logo)}
          onClick={onLogoClick}
        >
          <AuthImage src={logoSrc || LOGOBRANCH} alt="Logo" />
        </a>
        <Menu
          mode="vertical"
          selectedKeys={selectedKeys}
          className={clsx(classes.menu, overrideClasses.menu)}
          items={menuItems}
        />
      </Layout.Sider>
      {selectedSubMenu && (
        <Menu
          className={clsx(
            classes['menu-full-height'],
            overrideClasses['menu-full-height']
          )}
          selectedKeys={selectedKeys}
          style={{ width: 220 }}
          items={subMenus}
          mode="inline"
          onMouseLeave={() => {
            if (subMenuFullHeight) {
              setSelectedSubMenu(null)
            }
          }}
        />
      )}
    </div>
  )
}
